import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserGatewayService } from 'src/app/shared/gateways/user-gateway.service';
import { OAuth2Config } from 'src/app/shared/models/oauth2.model';
import { OAuth2Service } from 'src/app/shared/services/oauth2.service';
import { AbstractSubscriptionDestroyer } from 'src/app/shared/utils/abstract-subscription-destroyer';
import { STORE } from 'src/app/shared/utils/constants';
import { environment } from '../../../../environments/environment';
import {UnityService} from "../../../shared/callback/unity.service";
import {takeUntil, tap} from 'rxjs';
import {init, track} from "@amplitude/analytics-browser";
import {ServerZone} from "@amplitude/analytics-types";
import { v4 as uuidv4 } from 'uuid';
import {CommonService} from "../../../shared/services/common.service";
import { PromoInOfferListResponse, User, UserPromo } from '../../../shared/models/user.model';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'wkt-offer-page',
  templateUrl: './offer-page.component.html',
  styleUrls: ['./offer-page.component.scss'],
})
export class OfferPageComponent extends AbstractSubscriptionDestroyer implements OnInit {
  platform: 'play_store' | 'app_store' | string = 'play_store';
  noSpecialOffer: boolean = false;
  disabledButton: boolean = false;
  offerAccessExpired: boolean = false;


  selectedButton: number | undefined;
  price: any;
  country : string | undefined;

  disabledSubscriptionButton: boolean = false;
  subscriptionButtonLabel = 'my_account_subscribe';
  toutcanal: boolean = false;
  canalplus: boolean = false;

  othersOffers: PromoInOfferListResponse[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly oAuth2Service: OAuth2Service,
    private readonly unityService: UnityService,
    private readonly userGatewayService: UserGatewayService,
    private readonly commonService : CommonService,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.unityService.clearCanalCookies()

    this.route.queryParams.subscribe((params) => {
      if (params['platform']) {
        if (params['platform'] === 'android') this.platform = 'play_store';
        if (params['platform'] === 'ios') this.platform = 'app_store';
      }
      if (params['localized_price_string']) {
        localStorage.setItem( STORE.APPLICATON_PRICE ,params['localized_price_string']);
      }
      if (params['country']) {
        const countryCode = params['country'];
        this.country = countryCode.toUpperCase()
        this.userGatewayService.getCountryByCode(countryCode.toUpperCase())
          .pipe(takeUntil(this.destroyData))
          .subscribe( country => {
            console.log(country)
            if (!!country.legacy) {
              this.noSpecialOffer = true;
            }  else {
              console.log(country)
              if (country.offers?.includes('canalplus')) {
                this.noSpecialOffer = false;
                this.canalplus = true;
                console.log("canalplus")
              }
              if (country.offers?.includes('toutcanal')) {
                this.noSpecialOffer = false;
                this.toutcanal = true;
                console.log("tout canal")
              }
            }
          })
      }
      if (params[STORE.DEVICE_ID ]) {
        localStorage.setItem( STORE.DEVICE_ID , params[STORE.DEVICE_ID ]);
      }
      console.log("Event ")
    });

    this.commonService.initAmplitude().then(value => {
      const event = "offers" ;
      const prop = {
        'category' : 'navigation',
        'type' : "page_view",
        'country': this.country,
        'platform': this.platform,
      }
      track(event, prop);
    })

    if (localStorage.getItem(STORE.APPLICATON_PRICE)) {
      this.price = localStorage.getItem(STORE.APPLICATON_PRICE)
    }
    this.userGatewayService.getUser(localStorage.getItem(STORE.USER_UUID)!)
      .pipe(
        takeUntil(this.destroyData)
      )
      .subscribe(user => {
        this.othersOffers = user.availableOffers;
        console.log("hadRightAtLeastOnceInThePast ===> "  + user.hadRightAtLeastOnceInThePast)
        if(!!this.country && !!user && this.country !== user.countryCode?.toLocaleLowerCase() && !!user.hasRight) {
          this.setUserCountry(user);
        } else {
          this.managerRight(user)
        }
      });


    if (this.oAuth2Service.checkCredentials()) {
      console.log('user is Authenticate');
    } else {
      console.log('user is not Authenticate');
    }
  }

  goToAccount() {
    this.router.navigateByUrl('/user/account?uuid=' + localStorage.getItem(STORE.USER_UUID)! + '&platform=' + localStorage.getItem(STORE.PLATFORM)!);
  }

  gotoCanalPlus(buttonNumber: number) {
    this.selectedButton = buttonNumber;
    this.disabledButton = true;

    this.subscriptionButtonLabel = 'my_account_subscribe';
    this.disabledSubscriptionButton = false;



    if (buttonNumber == 0) {
      const event = "TransactionAttempt";
      const discovery_free_month = {
        "provider" : "canal_plus",
        "payment_gateway" : "p@ss",
        "payment_method" : "prepaid_3rd_party_bundle",
        "offer_name" : "discovery_free_month"
      };
      localStorage.setItem(STORE.TRANSACTION_ATTEMPT,JSON.stringify(discovery_free_month));
      track( event, discovery_free_month);
    } else {
      const event = "TransactionAttempt";
      const unlimited_monthly_access = {
        "provider" : "canal_plus",
        "payment_gateway" : "p@ss",
        "payment_method" : "prepaid_3rd_party_bundle",
        "offer_name" : "unlimited_monthly_access"
      }
      localStorage.setItem(STORE.TRANSACTION_ATTEMPT,JSON.stringify(unlimited_monthly_access))
      track( event, unlimited_monthly_access);
    }

    this.oAuth2Service.login(environment.auth2 as OAuth2Config);
  }

  gotoOthersOffers(offer: PromoInOfferListResponse) {
    this.userGatewayService.validOfferCode(localStorage.getItem(STORE.USER_UUID)!, offer.uuid)
    .pipe(
      takeUntil(this.destroyData)
    )
    .subscribe((userPromo) => {
      if (userPromo.hasExpired) {
        this.router.navigateByUrl('/user/3rd_party_login/' + offer.name + '/error')
      } else {
        this.unityService.confirmUserLoggedToUnity(STORE.USER_UUID)
        this.router.navigateByUrl('/user/3rd_party_login/' + offer.name + '/redirect')
      }
    }, error => {
      this.router.navigateByUrl('/user/3rd_party_login/' + offer.name + '/error')
    })
  }

  getButtonLabel (buttonNumber: number) {
    if (this.disabledButton && this.selectedButton === buttonNumber) {
      return 'authenticate_by_code_connecting';
    }
    return 'authenticate_by_code_connect';
  }

  activateNatif() {
    this.disabledSubscriptionButton = true;
    this.subscriptionButtonLabel = 'authenticate_by_code_connecting';

    const event = "TransactionAttempt";
    const regular_subscription = {
      "provider": "app_store",
      "payment_gateway": "in_app_purchase",
      "payment_method": "unknown",
      "offer_name": "regular_subscription"
    };
    track( event, regular_subscription);
    this.unityService.activateNatif()
  }

  private setUserCountry(user: User){
    console.log('country call ' + this.country + ' and user country ' + user.countryCode + ' are not same')
      let url = 'offers?country=' + user.countryCode + '&platform=' + this.platform + '&uuid='+localStorage.getItem(STORE.USER_UUID)!;
      this.router.navigateByUrl(url);
  }

  private managerRight(user: User){
    if (!!user && !!user.hadRightAtLeastOnceInThePast && !user.hasRight) {
      this.offerAccessExpired = true
    } else if (!!user && !!user.hasRight) {
      this.offerAccessExpired = false
    }
  }

  goToHelp() {
    this.translate.get('help_url').subscribe(value => {
      this.unityService.goToUrl(value);
    })
  }

  centerOffers() {
    if(!!this.canalplus && !this.toutcanal && this.othersOffers.length === 0) {
      return true;
    }
    else if(!this.canalplus && !!this.toutcanal && this.othersOffers.length === 0) {
      return true;
    }
    else if(!this.canalplus && !this.toutcanal && this.othersOffers.length === 1) {
      return true;
    }
    return false;
  }
}
