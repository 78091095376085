import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UnityService } from 'src/app/shared/callback/unity.service';
import { UserGatewayService } from 'src/app/shared/gateways/user-gateway.service';
import { tap, takeUntil } from 'rxjs';
import { AbstractSubscriptionDestroyer } from 'src/app/shared/utils/abstract-subscription-destroyer';
import { STORE } from 'src/app/shared/utils/constants';
import {track} from "@amplitude/analytics-browser";
import {CommonService} from "../../../shared/services/common.service";

@Component({
  selector: 'wkt-account-deleting-page',
  templateUrl: './account-deleting-page.component.html',
  styleUrls: ['./account-deleting-page.component.scss'],
})
export class AccountDeletingPageComponent extends AbstractSubscriptionDestroyer implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly unityService: UnityService,
    private readonly userGatewayService: UserGatewayService,
    private readonly commonService: CommonService
  ) {
    super();
  }

  ngOnInit(): void {
    this.commonService.initAmplitude().then(value => {
      const event = "user/account/deleting";
      const prop = {
        "category" : "navigation",
        "type" : "page_view"
      }
      track(event, prop);
    })

    console.log('test');
  }

  confirmRemove() {
    this.userGatewayService
      .deleteUser(localStorage.getItem(STORE.USER_UUID)!)
      .pipe(
        tap(() => {
          this.unityService.gotoConfirmDeletedUser();
          localStorage.clear();
        }),
        takeUntil(this.destroyData)
      )
      .subscribe(() => {
        this.router.navigateByUrl('/user/account/deleted');
      });
  }

  notRemove() {
    this.router.navigateByUrl('/user/account?uuid=' + localStorage.getItem(STORE.USER_UUID)! + '&platform=' + localStorage.getItem(STORE.PLATFORM)!);
  }
}
