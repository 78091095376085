<nav class="navbar navbar-light fixed-top bg-wkGray prevent-select">
  <div class="container-fluid text-wkGray">
    <span *ngIf="!hideBackButton" class="navbar-brand mb-0 chevron-class text-wkGray" (click)="goBack()"
      ><i class="bi bi-chevron-left"></i
    ></span>
    <div class="d-grid gap-2 my-2 w-100">
      <div class="mx-auto"> {{ 'header_step' | translate }} 1/3 </div>
    </div>
  </div>
</nav>

<div class="container-fuild h-100 pt-3 pb-3 pb-md-0 prevent-select">
  <div class="row mt-3 main-content tablet-screen-responsive mx-auto">
    <div class="col-12 px-0">
      <wkt-logo></wkt-logo>
    </div>

    <div class="col-12 px-0 align-self-end align-self-md-start">
      <form [formGroup]="loginForm" class="login_from" (ngSubmit)="askCode()" style="padding: 15px">
        <div class="mb-3">{{ 'identify_title' | translate }}</div>



        <div *ngIf="identifyType === 'PHONE'" class="input-group input-group-lg my-3">
          <button
            class="btn btn-outline-wkGray wkt-button border-left-radius border-right-radius boder-wkBolderGray"
            type="button"
            id="button-addon1"
            >+{{ selectedCountry?.phone }}</button
          >
          <input
            name="phone"
            formControlName="phone"
            type="tel"
            class="form-control wkt-button border-left-radius border-right-radius btn-outline-wkGray boder-wkBolderGray"
            placeholder="{{ 'identify_phone_number_placeholder' | translate }}"
            aria-label="Example text with button addon"
            aria-describedby="button-addon1" />
        </div>

        <div *ngIf="identifyType === 'EMAIL'" class="input-group input-group-lg my-3">
          <input
            name="email"
            formControlName="email"
            type="email"
            class="form-control wkt-button border-left-radius border-right-radius btn-outline-wkGray boder-wkBolderGray"
            placeholder="{{ 'identify_email_placeholder' | translate }}"
            aria-label="email"
            aria-describedby="inputGroup-sizing-lg" />
        </div>

        <button
          class="btn btn-secondary wkt-button-secondary-color-white btn-lg w-100 wkt-button border-left-radius border-right-radius"
          type="submit"
          [disabled]="disableSendButton()"
          >{{ 'identify_receive_code' | translate }}</button
        >
      </form>

      <div class="mb-3 text-center"
        >{{ 'identify_cgv' | translate }}
        <a
          (click)="goTOCGV()"
          class="text-secondary">
          {{ 'identify_cgv_action' | translate }}
        </a>
        {{ 'and' | translate }}
        <a
          (click)="goTOPrivacyPolicy()"
          class="text-secondary">
          {{ 'identify_privacy_action' | translate }}
        </a>
      </div>



    </div>

    <div class="col-12 px-0 align-self-end align-self-md-end">
      <div *ngIf="!hasCodePromo" class="text-center div-footer"
      >
        {{ 'wakatoon_coloring_book_prompt' | translate }}
        <a
          (click)="goBookLink()"
          class="text-secondary">
          {{ 'wakatoon_coloring_book_cta' | translate }}
        </a>

      </div>
    </div>

  </div>
</div>
