import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { takeUntil, tap } from 'rxjs';
import { UnityService } from 'src/app/shared/callback/unity.service';
import { UserGatewayService } from 'src/app/shared/gateways/user-gateway.service';

import { ValidationCodeResponse } from 'src/app/shared/models/validation-code.model';
import { OAuth2Service } from 'src/app/shared/services/oauth2.service';
import { AbstractSubscriptionDestroyer } from 'src/app/shared/utils/abstract-subscription-destroyer';
import { STORE } from 'src/app/shared/utils/constants';

import {Country} from "../../../shared/components/select-country/select-country.data";
import {track} from "@amplitude/analytics-browser";
import {CommonService} from "../../../shared/services/common.service";
import {User} from "../../../shared/models/user.model";

@Component({
  selector: 'wkt-user-provider',
  templateUrl: './user-provider.component.html',
  styleUrls: ['./user-provider.component.scss'],
})
export class UserProviderComponent extends AbstractSubscriptionDestroyer implements OnInit {
  token: string = '';
  mainInfo: string = '';
  buttonLabel: string = '';
  buttonUrl: string = '';
  provider: string = 'canalplus';
  section: string = 'redirect';



  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly userGatewayService: UserGatewayService,
    private readonly unityService: UnityService,
    private readonly oAuth2Service: OAuth2Service,
    private readonly commonService: CommonService
  ) {
    super();
  }

  ngOnInit(): void {
    debugger

    this.route.queryParams.subscribe((params) => {
      if (params['code']) {
        localStorage.setItem(STORE.USER_CANAL_AUTH_CODE, params['code']);
        //const url = 'user/3rd_party_login/canal/redirect';
        //this.router.navigateByUrl(url);
      }
      if (params['err_code']) {
        console.log('error code', params['err_code']);
      }
    });

    this.route.paramMap.subscribe((params) => {
      if (params.get('provider')) {
        this.provider = params.get('provider')!
      }
      if (params.get('section')) {
        this.section = params.get('section')!
      }
    });
    this.redirect();
  }

  clickButton(event: any) {
    this.router.navigateByUrl('offers?country=' + (JSON.parse(localStorage.getItem(STORE.USER_COUNTRY)!) as Country).code + '&platform=' + localStorage.getItem(STORE.PLATFORM)!);
  }

  private redirect() {
    console.log(this.route.snapshot.url);

    const completUrl = !!this.route.snapshot.url && this.route.snapshot.url.length > 3;

    if (!!completUrl && this.route.snapshot.url[3].path === 'redirect') {
      this.setloadingPage();
    } else if (!!completUrl && this.route.snapshot.url[3].path === 'error') {
      this.setErrorPage();
    }
  }

  private setErrorPage() {
    this.mainInfo = '3rd_party_login_error_message';
    this.buttonLabel = '3rd_party_login_error_retry';
    this.buttonUrl = 'offers';
  }

  private setloadingPage() {
    this.mainInfo = '3rd_party_login_redirect';
    setTimeout(() => {
      if (this.route.snapshot.url[2].path === 'canalplus')
        this.authenticateCanalPlus(localStorage.getItem(STORE.USER_CANAL_AUTH_CODE)!);
      else
        this.authenticateOther()

    }, 1000);
  }

  private authenticateCanalPlus(code: string) {
    this.userGatewayService
      .authenticate(code, localStorage.getItem(STORE.USER_UUID)!)
      .pipe(
        tap((user: ValidationCodeResponse) => this.confirmUserLoggedCanalPlus(user)),
        takeUntil(this.destroyData)
      )
      .subscribe(
        (user) => {
          console.log('user retrived', user);
        },
        (error) => {
          console.log('Error : ', error);
          const url = 'user/3rd_party_login/canal/error';
          this.router.navigateByUrl(url);
          this.setErrorPage();
        }
      );
  }

  private authenticateOther() {
    this.userGatewayService
      .getUser(localStorage.getItem(STORE.USER_UUID)!)
      .pipe(
        tap((user: User) => this.confirmUserLogged(user)),
        takeUntil(this.destroyData)
      )
      .subscribe(
        (user) => {
          console.log('user retrived', user);
        },
        (error) => {
          console.log('Error : ', error);
          const url = 'user/3rd_party_login/canal/error';
          this.router.navigateByUrl(url);
          this.setErrorPage();
        }
      );
  }

  private confirmUserLoggedCanalPlus(user: ValidationCodeResponse) {
    debugger
    if (!!user && !!user.hasRight && !user.doubleAccount) {
      this.commonService.initAmplitude().then(value => {
        const transactionAttempt = JSON.parse(localStorage.getItem(STORE.TRANSACTION_ATTEMPT)!)
        const event = "user/3rd_party_login/"+ this.provider + '/' + this.section;
        const prop = {
          "category" : "navigation",
          "type" : "page_view",
          "transactionAttempt": transactionAttempt
        }
        track(event, prop);
      })
      this.unityService.confirmUserLoggedToUnity(user.userUuid);
    } else {
      const url = 'user/3rd_party_login/' + this.provider + '/' + this.section;
      this.router.navigateByUrl(url);
      this.buttonLabel = '3rd_party_login_error_button_back_to_offers';
      this.buttonUrl = 'offers?country=' + (JSON.parse(localStorage.getItem(STORE.USER_COUNTRY)!) as Country).code + '&platform=' + localStorage.getItem(STORE.PLATFORM);
      if(!!user && !user.hasRight && !user.doubleAccount) {
        this.commonService.initAmplitude().then(value => {
          const transactionAttempt = JSON.parse(localStorage.getItem(STORE.TRANSACTION_ATTEMPT)!)
          const event = "user/3rd_party_login/"+ this.provider + '/' + this.section;
          const prop = {
            "category" : "navigation",
            "type" : "page_view",
            'err_code' : 'INELIGIBLE_ACCOUNT',
            "transactionAttempt": transactionAttempt
          }
          track(event, prop);
        })
        this.mainInfo = '3rd_party_login_canal_no_subscription';
      }  else if (!!user && user.doubleAccount) {
        this.commonService.initAmplitude().then(value => {
          const transactionAttempt = JSON.parse(localStorage.getItem(STORE.TRANSACTION_ATTEMPT)!)
          const event = "user/3rd_party_login/" + this.provider + '/' + this.section;
          const prop = {
            "category" : "navigation",
            "type" : "page_view",
            'err_code' : 'ACCOUNT_ALREADY_USED',
            "transactionAttempt": transactionAttempt
          }
          track(event, prop);
        })
        this.mainInfo = '3rd_party_login_canal_already_in_use';
      }
    }
  }

  private confirmUserLogged(user: User) {
    if (!!user && !!user.hasRight) {
      this.commonService.initAmplitude().then(value => {
        const transactionAttempt = JSON.parse(localStorage.getItem(STORE.TRANSACTION_ATTEMPT)!)
        const event = "user/3rd_party_login/"+ this.provider + '/redirect';
        const prop = {
          "category" : "navigation",
          "type" : "page_view",
          "transactionAttempt": transactionAttempt
        }
        track(event, prop);
      })
      this.unityService.confirmUserLoggedToUnity(user.id);
    } else {
      const url = 'user/3rd_party_login/' + this.provider + '/error';
      this.router.navigateByUrl(url);
      this.buttonLabel = '3rd_party_login_error_button_back_to_offers';
      this.buttonUrl = 'offers?country=' + (JSON.parse(localStorage.getItem(STORE.USER_COUNTRY)!) as Country).code + '&platform=' + localStorage.getItem(STORE.PLATFORM);
      if(!!user && !user.hasRight) {
        this.commonService.initAmplitude().then(value => {
          const transactionAttempt = JSON.parse(localStorage.getItem(STORE.TRANSACTION_ATTEMPT)!)
          const event = "user/3rd_party_login/" + this.provider + '/error';
          const prop = {
            "category" : "navigation",
            "type" : "page_view",
            'err_code' : 'INELIGIBLE_ACCOUNT',
            "transactionAttempt": transactionAttempt
          }
          track(event, prop);
        })
        this.mainInfo = '3rd_party_login_canal_no_subscription';
      }
    }
  }
}
