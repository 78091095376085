import { Injectable } from "@angular/core";
import { UserGatewayService } from "../../gateways/user-gateway.service";
import { AbstractSubscriptionDestroyer } from "../../utils/abstract-subscription-destroyer";
import { Country } from './select-country.data';
import { takeUntil } from 'rxjs';
import {UnityService} from "../../callback/unity.service";
import {STORE} from "../../utils/constants";

@Injectable({
    providedIn: 'root',
})
  export class SelectCountryService extends AbstractSubscriptionDestroyer {
    private _defaultCountry : Country = { code: 'FR', name: 'France', phone: '33', legacy: false };
    private _countryList : Array<Country> = [];
    private _serviceReady = true;

    constructor(private readonly userGatewayService: UserGatewayService){
      super();      
    }

    setInitialDefaultCountry()
    {
      this.setDefaultCountry(this._defaultCountry);
    }


    get defaultCountry () : Country {
      return this._defaultCountry
    }

    setDefaultCountry(defaultCountry: Country) {
      console.log('setDefaultCountry',defaultCountry)
      this._defaultCountry = defaultCountry;
      localStorage.setItem(STORE.USER_COUNTRY, JSON.stringify(defaultCountry))
      this._serviceReady = false;
      setTimeout(() => {this._serviceReady = true },400);
    }

    setDefaultCountryByCode(countryCode: string ) {
      this.userGatewayService.getCountryByCode(countryCode.toUpperCase())
      .pipe(takeUntil(this.destroyData))
      .subscribe( country => this.setDefaultCountry(country))
    }

    get serviceReady () : boolean {
      return this._serviceReady
    }
  }
