<nav class="navbar navbar-light fixed-top bg-wkGray prevent-select">
  <div class="container-fluid text-wkGray">
    <span class="navbar-brand mb-0 chevron-class text-wkGray" (click)="goBack()"><i
        class="bi bi-chevron-left"></i></span>
    <div class="d-grid gap-2 my-2 w-100">
      <div class="mx-auto"> {{ 'header_step' | translate }} 2/3 </div>
    </div>
  </div>
</nav>

<div class="container-fuild h-100 p-3 prevent-select">
  <div class="row mt-3 main-content tablet-screen-responsive mx-auto">
    <div class="col-12 text-center">
      <wkt-logo class="mb-5"></wkt-logo>
      <div class="mb-3 mt-5">
        <h4>{{ 'authenticate_by_code_code_sent' | translate }}</h4>
      </div>
      <div class="mb-3" style="word-break: break-all;">
        <h6>{{ email }}</h6>
      </div>
      <div *ngIf="emailType" class="mt-3">{{ 'authenticate_by_code_code_not_received_by_email' | translate }}
        <a (click)="reSendCode()" class="text-secondary"> {{ 'authenticate_by_code_code_not_received_action' | translate
          }} </a>
      </div>
      <div *ngIf="!emailType" class="mt-3">{{ 'authenticate_by_code_code_not_received_by_sms' | translate }}
        <a (click)="reSendCode()" class="text-secondary"> {{ 'authenticate_by_code_code_not_received_action' | translate
          }} </a>
      </div>
    </div>

    <div class="col-12 align-self-end align-self-md-start">
      <div class="mb-0">{{ 'authenticate_by_code_enter_code' | translate }}</div>
      <div *ngIf="displayErrorMessage" class="mb-0 text-danger text-error-message">{{
        'authenticate_by_code_error_code_mismatch' | translate }}</div>

      <div class="input-group input-group-lg my-3 wkt-button border-left-radius border-right-radius boder-wkBolderGray">
        <input [(ngModel)]="code" type="tel"
          class="form-control text-center wkt-button border-left-radius border-right-radius btn-outline-wkGray boder-wkBolderGray"
          placeholder="{{ 'authenticate_by_code_code_placeholder' | translate }}" aria-label="tel code"
          aria-describedby="inputGroup-sizing-lg" />
      </div>

      <button
        class="btn btn-secondary wkt-button-secondary-color-white btn-lg w-100 wkt-button border-left-radius border-right-radius "
        (click)="valideCode()" type="button">{{ 'authenticate_by_code_connect' | translate }}</button>
      <div class="mb-3 text-center">
        <br>
        <a (click)="goToHelp()" class="text-secondary">
          {{ 'help_url_prompt' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>