import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuth2Config } from '../models/oauth2.model';

@Injectable({
  providedIn: 'root',
})
export class OAuth2Service {
  constructor(private readonly http: HttpClient) {}

  login(config: OAuth2Config) {
    const scope = config.scope?.replace(new RegExp(' ', 'g'), '%20');
    const url = `${config.authority}?client_id=${config.clientId}&response_type=${config.responseType}&scope=${scope}&redirect_uri=${config.redirectUrl}`;
    window.location.href = url;
  }

  retrieveToken(config: OAuth2Config, code: string) {
    let headers = new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded; charset=utf-8' });

    let params = new URLSearchParams();
    params.append('grant_type', config.grantType);
    params.append('client_id', config.clientId);
    params.append('client_secret', config.clientSecret);
    params.append('redirect_uri', config.redirectUrl);
    params.append('code', code!);

    this.http.post(config.tokenUrl, params.toString(), { headers: headers }).subscribe(
      (data) => this.saveToken(data),
      (err) => alert('Invalid Credentials')
    );
  }

  saveToken(token: any) {
    var expireDate = new Date().getTime() + 1000 * token.expires_in;
    console.log(token.access_token);
    localStorage.setItem('access_token', token.access_token);
    localStorage.setItem('expireDate', expireDate.toString());

    console.log('Obtained Access token');
  }

  checkCredentials() {
    return localStorage.getItem('access_token');
  }
}
