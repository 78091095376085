<nav *ngIf="!hideHeaderNav && !byEmail" class="navbar navbar-light fixed-top bg-wkGray prevent-select">
  <div class="container-fluid text-wkGray">
    <span class="navbar-brand mb-0 chevron-class text-wkGray" (click)="goBack()"><i
        class="bi bi-chevron-left"></i></span>
    <div class="d-grid gap-2 my-2 w-100">
      <div class="mx-auto"> {{ 'header_my_account' | translate }}</div>
    </div>
  </div>
</nav>

<div class=" h-100 p-0 prevent-select">
  <div class="row mt-3 tablet-screen-responsive account-0-zone pt-2 px-4 mx-auto">
    <div class="col-12 top-position">
      <div class="mb-3 mt-2">{{ 'my_account_identifier' | translate }}</div>
      <div class="my-2">
        <h4 class="noOverflow">{{ identifiant }} </h4>
      </div>
      <button *ngIf="!byEmail"
        class="btn btn-outline-dark btn-lg my-2 wkt-button border-left-radius border-right-radius" (click)="logout()"
        type="button">{{ 'my_account_disconnect' | translate }}</button>

      <button *ngIf="!byEmail"
        class="btn btn-outline-wkBolderGray btn-lg my-2 wkt-button border-left-radius border-right-radius"
        (click)="removeAccount()" type="button">{{ 'my_account_delete_account' | translate }}</button>

      <div class="row py-0">
        <div class="col-12 my-3">
          <wkt-select-country [displayChangeCountry]="true" [showChangeCountry]="!hasRight && !byEmail"
            (selectedCountryCountryEvent)="coountryChange($event)"></wkt-select-country>
        </div>
      </div>

      <div class="row py-0 border-top">
        <div *ngIf="hasRight" class="col-12  align-self-start tablet-screen-responsive mx-auto">
          <div class="my-3 my-md-3">
            <h4>{{ 'my_account_my_suscription' | translate }}</h4>
          </div>
          <div>{{ 'expires_at' | translate }}: {{expireAt}}</div>
          <div *ngIf="user?.transaction?.autoRenewable">{{ 'subscription_type' | translate }}: <strong> mensuel
            </strong></div>
          <div> {{ 'autorenew' | translate }} : <strong *ngIf="user?.transaction?.autoRenewable"> Oui </strong> <strong
              *ngIf="!user?.transaction?.autoRenewable"> Non </strong></div>
          <div>{{ 'provider' | translate}} <strong> {{ user?.transaction?.partner }}</strong></div>

        </div>

        <div *ngIf="!hasRight" class="col-12 align-self-start tablet-screen-responsive mx-auto">
          <div class="my-3 my-md-3">
            <h4>{{ 'my_account_my_suscription' | translate }}</h4>
          </div>

          <div class="my-1 my-md-3">{{ 'my_account_no_active_subscription' | translate }}</div>

          <button *ngIf="!byEmail"
            class="btn btn-secondary wkt-button-secondary-color-white btn-lg w-100 wkt-button border-left-radius border-right-radius"
            type="button" (click)="goBackToOffers()">{{ 'my_account_subscribe' | translate }}</button>
        </div>
      </div>

      <div *ngIf="!hasRight && iOSPlatform && !byEmail">
        <button class="btn btn-outline-dark btn-lg my-2 wkt-button border-left-radius border-right-radius"
          (click)="callNatifIapRestore()" type="button">{{ 'iap_restore' | translate }}</button>
      </div>
    </div>
  </div>

  <div *ngIf="false" class="row account-1-zone px-2">
    <div class="col-12 bg-light tierce-4-zone p-4 align-self-end">
      <div class="row tablet-screen-responsive">
        <div class="col-4 text-center"><wkt-logo [logoUrl]="platform"></wkt-logo></div>
        <div class="col-8 border-left">
          <div><strong>Accès illimité</strong></div>
          <div><small>3,9€/mois</small></div>
          <button
            class="btn btn-secondary wkt-button-secondary-color-white btn-lg w-100 wkt-button border-left-radius border-right-radius"
            type="button">{{ 'my_account_no_active_subscription_button' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

</div>