import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {track} from "@amplitude/analytics-browser";
import {CommonService} from "../../../shared/services/common.service";
import {UnityService} from "../../../shared/callback/unity.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'wkt-auth-notsend-code-page',
  templateUrl: './auth-notsend-code-page.component.html',
  styleUrls: ['./auth-notsend-code-page.component.scss'],
})
export class AuthNotsendCodePageComponent implements OnInit {
  constructor(private readonly router: Router,
              private readonly route: ActivatedRoute,
              private readonly commonService: CommonService,
              private readonly unityService: UnityService,
              private readonly translate: TranslateService ) {}

  private type  = ""
  promocode = '';
  email : string = 'no email';
  
  ngOnInit(): void {

    var mail_or_phone = localStorage.getItem('identifyValue')
    this.email = mail_or_phone ?? '';
   
    this.route.queryParams.subscribe((params) => {
      if (params['type']) {
        this.type = (params['type'] as string).toLowerCase();
        this.commonService.initAmplitude().then(value => {
          const event = "user/code/notsent?type=" + this.type;
          const prop = {
            "category" : "navigation",
            "type" : "page_view"
          }
          track(event, prop);
        })
      }
      if(params['promocode']) {
        this.promocode = params['promocode'];
      } else {
        this.promocode = '';
      }
    });
  }

  retry() {
    let url = '/user/identifier?type=' + this.type + (this.promocode == '' ? '' : '&promocode='+this.promocode);
    this.router.navigateByUrl(url);
  }

  faq() {
    this.translate.get('help_url').subscribe(value => {
      this.unityService.goToUrl(value);
    })
  }

}
