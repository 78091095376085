import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { takeUntil, tap } from 'rxjs';
import { UnityService } from 'src/app/shared/callback/unity.service';
import { UserGatewayService } from 'src/app/shared/gateways/user-gateway.service';
import { User } from 'src/app/shared/models/user.model';
import { AbstractSubscriptionDestroyer } from 'src/app/shared/utils/abstract-subscription-destroyer';
import { STORE } from 'src/app/shared/utils/constants';
import { AccountValidationRequest } from '../../../shared/models/validation-code.model';
import {Country} from "../../../shared/components/select-country/select-country.data";
import {track} from "@amplitude/analytics-browser";
import {CommonService} from "../../../shared/services/common.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'wkt-auth-code-page',
  templateUrl: './auth-code-page.component.html',
  styleUrls: ['./auth-code-page.component.scss'],
})
export class AuthCodePageComponent extends AbstractSubscriptionDestroyer implements OnInit {
  code: string = '';
  displayErrorMessage: boolean = false;
  emailType: boolean = true;
  email : string = '';
  promocode = '';

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userGatewayService: UserGatewayService,
    private readonly unityService: UnityService,
    private readonly commonService: CommonService,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {

    var mail_or_phone = localStorage.getItem('identifyValue')
    this.email = mail_or_phone ?? '';
     
    this.route.queryParams.subscribe((params) => {

      this.email = localStorage.getItem('identifyValue') ?? '';
      
      if (params['type']) {
        this.emailType = params['type'] === 'email';
      }
      
      if (params['promocode']) {
        this.promocode = params['promocode'];
      } else {
        this.promocode = '';
      }
      this.commonService.initAmplitude().then(value => {
        console.log('code');
        const event = "user/code/verify";
        const prop = {
          "category" : "navigation",
          "type" : "page_view",
          "promocode" : this.promocode
        }
        track(event, prop);
      })
    })
  }

  valideCode() {
    this.displayErrorMessage = false;
    const validationRequet: AccountValidationRequest = {
      code: this.code,
      userUuid: localStorage.getItem(STORE.USER_UUID)!,
    };
    console.log('validationCodeRequest', validationRequet);

    if (this.promocode === '') {
      this.defaultValidationCode();
    } else {
      this.promoValidationCode();
    }
  }

  reSendCode(){
   this.goBack();
  }

  goBack() {
    const type = localStorage.getItem(STORE.USER_IDENTIY_TYPE) == null ? 'email' : localStorage.getItem(STORE.USER_IDENTIY_TYPE)?.toLocaleLowerCase();
    let url = '/user/identifier?type=' + type + (this.promocode == '' ? '' : '&promocode='+this.promocode);
    this.router.navigateByUrl(url);
  }

  private setUserIdToUnity(user: User) {
    if (!!user && !!user.id) {
      this.unityService.setUserIdToUnity(user.id);
    } else {
      console.log('user not found');
    }
  }

  goToHelp() {
    this.translate.get('help_url').subscribe(value => {
      this.unityService.goToUrl(value);
    })
  }

  private promoValidationCode() {

    this.userGatewayService
      .valideCode(this.code, localStorage.getItem(STORE.USER_UUID)!)
      .pipe(
        takeUntil(this.destroyData)
      )
      .subscribe(
        (userFormBack) => {
          this.userGatewayService
            .checkPromoCode(localStorage.getItem(STORE.USER_UUID)!, this.promocode)
            .pipe(
              takeUntil(this.destroyData)
            ).subscribe(
            (userPromo) => {
              console.log('user retrieved', userPromo);
              const url = '/promo/instruction?' + 'promocode='+ this.promocode + '&freeDaysCount=' + userPromo.freeDaysCount + '&valid=' + !userPromo.hasExpired + ( !!userPromo.oneTimeToken ? '&onetimetoken=' + userPromo.oneTimeToken : '');
              this.router.navigateByUrl(url)
            } ,
            (error) =>  {
              if (this.promocode != '') {
                const url = '/promo/instruction?' + 'promocode=' + this.promocode + '&valid=false';
                this.router.navigateByUrl(url)
              }
              if (!!error.status && error.status === 404) {
                this.displayErrorMessage = true;
              }
            }
          );
        },
        (error) =>  {
          if (!!error.status && error.status === 404) {
            this.displayErrorMessage = true;
          }
        }
      );


  }

  private defaultValidationCode() {

    this.userGatewayService
      .valideCode(this.code, localStorage.getItem(STORE.USER_UUID)!)
      .pipe(
        takeUntil(this.destroyData)
      )
      .subscribe(
        (userFormBack) => {
          console.log('user retrieved', userFormBack);
          this.setUserIdToUnity(userFormBack);
          setTimeout(() =>  {
              if (!userFormBack.hasRight)
                this.router.navigateByUrl('/offers?country=' + (JSON.parse(localStorage.getItem(STORE.USER_COUNTRY)!) as Country).code + '&platform=' + localStorage.getItem(STORE.PLATFORM)!);
              else
                this.unityService.confirmUserLoggedToUnity(userFormBack.id!)
            },
            1000);
        },
        (error) =>  {
         if (!!error.status && error.status === 404) {
          this.displayErrorMessage = true;
         }
        }
      );
  }
}
