import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class AbstractSubscriptionDestroyer implements OnDestroy {
  protected destroyData = new Subject<void>();

  ngOnDestroy(): void {
    this.destroyData.next();
    this.destroyData.complete();
  }
}
