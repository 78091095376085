import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingComponent } from './components/building/building.component';
import { LogoComponent } from './components/logo/logo.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SelectCountryComponent } from './components/select-country/select-country.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpErrorInterceptor } from './gateways/http-error-interceptor';
import { InfoComponent } from './components/info/info.component';
import { ActivatedRoute } from '@angular/router';
import { STORE } from './utils/constants';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [BuildingComponent, LogoComponent, SelectCountryComponent, InfoComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BuildingComponent,
    LogoComponent,
    SelectCountryComponent,
    InfoComponent,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }],
})
export class SharedModule {
  constructor(private translateService: TranslateService, private readonly route: ActivatedRoute) {
    this.translateService.addLangs(['en', 'fr']);

    this.route.queryParams.subscribe((params) => {
      let browserLang = 'en';
      if (params['lang']) {
        browserLang = params['lang'];
        console.log('detect language in url', browserLang);
      } else {
        browserLang = this.translateService.getBrowserLang()!;
        console.log('detect language from browser', browserLang);
      }

      let selectedLang = browserLang.match(/en|fr/) ? browserLang : 'en';
      console.log('Using language for page', selectedLang);
      this.translateService.use(selectedLang);
      console.log('TranslateService language ', this.translateService.currentLang);

      if (params['uuid']) {
        localStorage.setItem(STORE.USER_UUID, params['uuid']);
      }
    });
  }
}
