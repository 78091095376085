import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  get(url: string, params?: HttpParams): Observable<any> {
    return this.http
      .get<Response>(environment.apiBaseURL + url, { params })
      .pipe(map((response: Response) => response));
  }

  getFront(url: string, params?: HttpParams): Observable<any> {
    return this.http
      .get<Response>(environment.apiBaseURL + url, { params })
      .pipe(map((response: Response) => response));
  }

  post(url: string, body: any, params?: HttpParams): Observable<any> {
    return this.http
      .post<Response>(environment.apiBaseURL + url, body, { params })
      .pipe(map((response: Response) => response));
  }

  put(url: string, body: any, params?: HttpParams): Observable<any> {
    return this.http
      .put<Response>(environment.apiBaseURL + url, body, { params })
      .pipe(map((response: Response) => response));
  }

  patch(url: string, body: any, params?: HttpParams): Observable<any> {
    return this.http
      .patch<Response>(environment.apiBaseURL + url, body, { params })
      .pipe(map((response: Response) => response));
  }

  delete(url: string, params?: HttpParams): Observable<any> {
    return this.http
      .delete<Response>(environment.apiBaseURL + url, { params })
      .pipe(map((response: Response) => response));
  }

  buildUrlWithPathVaribale(url: string, variableRegex: string, variableValue: any): string {
    return url.replace(variableRegex, variableValue);
  }
}
