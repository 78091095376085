import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, tap } from 'rxjs';
import { UserGatewayService } from 'src/app/shared/gateways/user-gateway.service';
import { AbstractSubscriptionDestroyer } from 'src/app/shared/utils/abstract-subscription-destroyer';
import { STORE } from 'src/app/shared/utils/constants';
import { FormValidatorProvider } from 'src/app/shared/utils/form-validator';
import { Country } from '../../../shared/components/select-country/select-country.data';
import {UnityService} from "../../../shared/callback/unity.service";
import {track} from "@amplitude/analytics-browser";
import {CommonService} from "../../../shared/services/common.service";

@Component({
  selector: 'wkt-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent extends AbstractSubscriptionDestroyer implements OnInit {
  identifyType =  'EMAIL'  ;
  selectedCountry: Country | undefined;
  hideBackButton: boolean = false;
  disabledSendButton: boolean = false;
  public loginForm!: FormGroup;

  promocode = '';
  hasCodePromo = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly vD: FormValidatorProvider,
    private readonly userGatewayService: UserGatewayService,
    private readonly unityService: UnityService,
    private readonly commonService: CommonService
  ) {
    super();
  }

  ngOnInit(): void {

    
    this.route.queryParams.subscribe((params) => {
      if (params['type']) {
        const type = (params['type'] as string).toUpperCase();
        this.identifyType = type;
        localStorage.setItem(STORE.USER_IDENTIY_TYPE, type);
        this.commonService.initAmplitude().then(r => {
          const event = "user/identify?type=" + type.toLowerCase();
          const prop = {
            "category" : "navigation",
            "type" : "page_view",
            "promocode" : this.promocode
          }
          track(event, prop);
        })
      }

      if (params['hide_back_button']) {
        this.hideBackButton = params['hide_back_button'] === 'true' ? true : false
      }

      if(params['promocode']) {
        this.promocode = params['promocode'];
        this.hasCodePromo = true;
      } else {
        this.promocode = '';
      }
    });

    localStorage.setItem(STORE.USER_IDENTIY_TYPE, this.identifyType);
    this.selectedCountry = JSON.parse(localStorage.getItem(STORE.USER_COUNTRY)!);
    this.initForm(this.identifyType);
  }

  selectedType(type: 'EMAIL' | 'PHONE') {
    localStorage.setItem(STORE.USER_IDENTIY_TYPE, type);
    this.identifyType = type;
    this.initForm(this.identifyType);
    const url = 'user/identifier?type=' + type.toLowerCase() + (this.promocode == '' ? '' : '&promocode='+this.promocode);
    this.router.navigateByUrl(url)
  }

  selectionType(type: string) {
    if (this.identifyType === type) {
      return 'btn-secondary wkt-button-secondary-color-white';
    }
    return 'btn-outline-wkBolderGray';
  }

  disableSendButton() {
    if (this.disabledSendButton === true) {
      return true;
    }
    return this.loginForm.invalid;
  }

  askCode() {
    console.log(this.loginForm.value);
    this.disabledSendButton = true;

    const countryCode = (JSON.parse(localStorage.getItem(STORE.USER_COUNTRY)!) as Country).code;
    if (this.identifyType === 'EMAIL') {
      const email = this.loginForm.value['email'];
      localStorage.setItem('identifyValue', email);
      this.userGatewayService
        .getCodeByEmail(email, countryCode, this.promocode)
        .pipe(
          tap((user) => localStorage.setItem(STORE.USER_UUID, user.uuid)),
          takeUntil(this.destroyData)
        )
        .subscribe(
          () => {
            const url = '/user/code/verify?type=email' + (this.promocode == '' ? '' : '&promocode='+this.promocode);
            this.router.navigateByUrl(url);
          },
          () => this.router.navigateByUrl('user/code/notsent?type=email' + (this.promocode == '' ? '' : '&promocode='+this.promocode))
        );
    } else {
      const phone = `+${this.selectedCountry?.phone}${this.loginForm.value['phone']}`;
      localStorage.setItem('identifyValue', phone);
      this.userGatewayService
        .getCodeBySMS(phone, countryCode, this.promocode)
        .pipe(
          tap((user) => localStorage.setItem(STORE.USER_UUID, user.uuid)),
          takeUntil(this.destroyData)
        )
        .subscribe(
          () => {
            const url = '/user/code/verify?type=sms' + (this.promocode == '' ? '' : '&promocode='+this.promocode);
            this.router.navigateByUrl(url);
          },
          () => this.router.navigateByUrl('user/code/notsent?type=sms' + (this.promocode == '' ? '' : '&promocode='+this.promocode))
        );
    }
  }

  goBack() {
    // Get Initial URL from local storage
    var initialUrl = localStorage.getItem(STORE.INITIAL_URL) ?? 'intro';    
    // Navigate to Initial URL
    // remove all characters before intro in initialUrl
    var url = initialUrl.split('intro');
    if (url.length > 1) {
      initialUrl = 'intro' + url[1];
    }else{
      initialUrl = 'intro';
    }

    this.router.navigateByUrl(initialUrl);
  }

  initForm(identifyType: string) {
    if (identifyType === 'EMAIL') {
      this.loginForm = this.fb.group({
        email: this.fb.control(null, [this.vD.validateEmail()]),
      });
    } else {
      this.loginForm = this.fb.group({
        phone: this.fb.control(null, [this.vD.validateNumber(),Validators.required,Validators.minLength(5)]),
      });
    }
  }

  goTOCGV() {
    this.unityService.cgv()
  }

  goBookLink() {
    let referrerLink = "user/identify?type=" + this.identifyType.toLowerCase();
    if (this.promocode !== "") {
      referrerLink += "&promocode="
      referrerLink += this.promocode
    }
    this.unityService.booklink(referrerLink)
  }

  goTOPrivacyPolicy() {
    this.unityService.privacyPolicy()
  }
}
