import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AccountModule } from './account/account.module';
import { OfferModule } from './offer/offer.module';
import { UserModule } from './user/user.module';
import { UserComponent } from './user/user.component';
import { ReplacePipe } from './replace.pipe';
import * as Sentry from "@sentry/angular-ivy"

@NgModule({
    declarations: [AppComponent, UserComponent, ReplacePipe],
    imports: [BrowserModule, HttpClientModule, AppRoutingModule, SharedModule, UserModule, AccountModule, OfferModule],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
    ], exports: [
        ReplacePipe
    ],

})
export class AppModule { }
