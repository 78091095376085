import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'wkt-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent {
  @Input() mainInfo: string = '';
  @Input() subInfo: string = '';
  @Input() buttonLabel: string = '';
  @Input() buttonUrl: string = '';
  @Output() buttonAction = new EventEmitter<any>();

  constructor(private readonly router: Router) {}

  action() {
    this.buttonAction.emit('click');
  }
}
