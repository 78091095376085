<div class="container h-100">
  <div class="row bg-light h-100 align-items-center text-center justify-content-center">
    <div class="col-12 text-primary">
      <h1>WAKATOON</h1>
    </div>

    <div class="col-12 col-md-6 my-1 my-md-3 text-secondary"> Building .. </div>
    <div class="col-12 col-md-6 my-1 my-md-3 text-secondary"> come back later .. :) </div>
  </div>
</div>
