import { NgModule } from '@angular/core';
import { AccountComponent } from './account.component';
import { SharedModule } from '../shared/shared.module';
import { AccountPageComponent } from './components/account-page/account-page.component';
import { AccountRoutingModule } from './account-routing.module';
import { AccountDeletingPageComponent } from './components/account-deleting-page/account-deleting-page.component';
import { AccountDeletedPageComponent } from './components/account-deleted-page/account-deleted-page.component';

@NgModule({
  declarations: [AccountComponent, AccountPageComponent, AccountDeletingPageComponent, AccountDeletedPageComponent],
  imports: [SharedModule, AccountRoutingModule],
})
export class AccountModule {}
