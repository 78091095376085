import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnityService } from 'src/app/shared/callback/unity.service';
import { UserGatewayService } from 'src/app/shared/gateways/user-gateway.service';
import { tap, takeUntil } from 'rxjs';
import { AbstractSubscriptionDestroyer } from 'src/app/shared/utils/abstract-subscription-destroyer';
import { Country } from '../../../shared/components/select-country/select-country.data';
import { STORE } from 'src/app/shared/utils/constants';
import { SelectCountryService } from 'src/app/shared/components/select-country/select-country.service';
import { User } from 'src/app/shared/models/user.model';
import {track} from "@amplitude/analytics-browser";
import {CommonService} from "../../../shared/services/common.service";

@Component({
  selector: 'wkt-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
})
export class AccountPageComponent extends AbstractSubscriptionDestroyer implements OnInit {
  platform: 'play_store' | 'app_store' | string = 'play_store';
  phone: string = '';
  hasRight: boolean = false;
  hideHeaderNav: boolean = false;
  byEmail: boolean = false;

  token: string | undefined;
  expireAt: String = "";

  userCountry: Country | undefined;
  user: User | undefined;
  provider: string = '';
  iOSPlatform: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userGatewayService: UserGatewayService,
    private readonly unityService: UnityService,
    private readonly selectCountryService: SelectCountryService,
    private readonly commonService: CommonService
  ) {
    super();
  }
  ngOnInit(): void {
    this.commonService.initAmplitude().then(r => {
      const event = "user/account";
      const prop = {
        "category" : "navigation",
        "type" : "page_view"
      }
      track(event, prop);
    })

    this.route.queryParams.subscribe((params) => {
      if (params['hide_header']) {
        this.hideHeaderNav = params['hide_header'] === 'true' ? true : false
      }

      if (params['platform']) {
        if (params['platform'] === 'android') {
          this.platform = 'play_store';
        }
        if (params['platform'] === 'ios') {
          this.platform = 'app_store';
          this.iOSPlatform = true
        }
        localStorage.setItem('platform', params['platform'])
      }

      if (params['country']) {
        this.getCountry(params['country'])
      }

      if (params['uuid']) {
        this.byEmail = false;
        
        this.userGatewayService
          .getUser(params['uuid'])
          .pipe(
            tap((user) => {
              if (user == null || (!!user && !!user.hasRight)) {
                this.unityService.gotoUserSubscriber();
              }
            }),
            takeUntil(this.destroyData)
          )
          .subscribe((user) => {
            if (user != null) {
              console.log('user fetch', user);
              localStorage.setItem(STORE.USER_UUID, user.id!);
              localStorage.setItem(STORE.USER_PHONE, user.phone!);
              this.phone = user?.phone!;
              this.hasRight = user.hasRight;
              this.expireAt = user.hasAccessTill!;
              this.getCountry(user.countryCode!);
              this.user = user;
            } else {
              this.phone = '+33600000000';
              this.hasRight = true;
            }
          });
      }
/*
      if (params['email']) {
        this.byEmail = true;
        this.userGatewayService
          .getUserByMail(params['email'])
          .pipe(
            tap((user) => {
              if (user == null || (!!user && !!user.hasRight)) {
                this.unityService.gotoUserSubscriber();
              }
            }),
            takeUntil(this.destroyData)
          )
          .subscribe((user) => {
            if (user != null) {
              console.log('user fetch', user);
              localStorage.setItem(STORE.USER_UUID, user.id!);
              localStorage.setItem(STORE.USER_PHONE, user.phone!);
              this.phone = user?.phone!;
              this.hasRight = user.hasRight;
              this.expireAt = user.hasAccessTill!;
              this.getCountry(user.countryCode!);
              this.user = user;
            } else {
              this.phone = '+33600000000';
              this.hasRight = true;
            }
          });
      }
      */
    });

  }

  logout() {
    this.unityService.gotoUnityLogout();
  }

  removeAccount() {
    this.router.navigateByUrl('/user/account/delete');
  }

  getToken() {
    return this.token;
  }

  goBack() {
    this.unityService.gotoUnityAccountBack();
  }

  coountryChange(country: Country) {
  }

  get identifiant() {
    if(!!this.user && !!this.user.phone) {
      if (!!this.userCountry && !!this.userCountry.phone) {
        return '+' + this.userCountry?.phone + '  ' + this.phone.substring(this.userCountry!.phone!.length + 1)
      }
      return this.phone;
    }
    return this.user?.email;
  }

  private getCountry(countryCode: string) {
    this.userGatewayService.getCountryByCode(countryCode)
    .pipe(takeUntil(this.destroyData))
    .subscribe(country => {
          localStorage.setItem(STORE.USER_COUNTRY, JSON.stringify(country));
          this.selectCountryService.setDefaultCountry(country);
          this.userCountry = country;
        }, () => console.log('country not found'));
  }

  goBackToOffers() {
    this.router.navigateByUrl("offers?country=" + this.userCountry?.code! + '&platform=' + localStorage.getItem(STORE.PLATFORM));
  }

  callNatifIapRestore() {
    this.unityService.callNatifIap()
  }
}
