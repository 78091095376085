import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {track} from "@amplitude/analytics-browser";
import {CommonService} from "../../../shared/services/common.service";
import { UnityService } from 'src/app/shared/callback/unity.service';

@Component({
  selector: 'wkt-account-deleted-page',
  templateUrl: './account-deleted-page.component.html',
  styleUrls: ['./account-deleted-page.component.scss'],
})
export class AccountDeletedPageComponent implements OnInit {
  constructor(private readonly router: Router,
              private readonly commonService: CommonService,
              private readonly unityService: UnityService) {}

  ngOnInit(): void {
    this.commonService.initAmplitude().then(r => {
      console.log('test');
      const event = "user/account/delete";
      const prop = {
        "category" : "navigation",
        "type" : "page_view"
      }
      track(event, prop);
    })
  }

  continue() {
    this.unityService.actionDeletedUser();
    this.router.navigateByUrl('');
  }
}
