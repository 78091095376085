<nav class="navbar navbar-light fixed-top bg-wkGray prevent-select">
  <div class="container-fluid text-wkGray">
    <span (click)="goToAccount()" class="navbar-brand mb-0 chevron-class text-wkGray"
      ><i class="bi bi-person-circle"></i
    ></span>
    <div class="d-grid gap-2 my-2 w-100">
      <div class="mx-auto"> {{ 'header_step' | translate }} 3/3 </div>
    </div>
  </div>
</nav>

<div class="container-fuild h-100 p-0 prevent-select">
  <div class="row mt-3 h-100 main-content mx-auto">
    <div class="col-12 px-4 tierce-0-zone tablet-screen-responsive mx-auto prevent-select">
      <div *ngIf="!offerAccessExpired" class="mb-3"
        ><h5>{{ 'all_offers_title' | translate }}</h5></div
      >
      <div *ngIf="offerAccessExpired" class="mb-3"
        ><h5>{{ 'access_expired_offers_title' | translate }}</h5></div
      >

      <div *ngIf="!noSpecialOffer"
        ><h6>{{ 'all_offers_prompt' | translate }}</h6></div
      >
      <div *ngIf="noSpecialOffer"
        ><h6>{{ 'subscription_offers_prompt' | translate }}</h6></div
      >
    </div>

    <div class="mb-3 text-center">
      <br>
      <a
        (click)="goToHelp()"
        class="text-secondary">
        {{ 'help_url_prompt' | translate }}
      </a>
    </div>

    <div *ngIf="!noSpecialOffer" class="col-12 tierce-3-zone mt-2 px-4 align-self-start mx-auto">

      <div class="row" class="row mt-0 pb-md-2  mx-auto text-md-center" [class.justify-content-center]="centerOffers()">
        <div *ngIf="canalplus"  class="col-12 col-md-6 my-2">
          <div class="row offer-height pb-md-2 mx-auto text-md-center h-100 justify-content-end">
            <div class="col-4 col-md-12  text-center d-flex align-items-center my-md-3">
              <wkt-logo class="mx-md-auto" [logoUrl]="'canalplus'"></wkt-logo>
            </div>
            <div class="col-8 border-xs-left mx-md-auto">
              <div class="offer-title">{{ 'canal_1_month_discover' | translate }}</div>
              <div class="offer-subtitle">{{ 'canal_offer_in_subscription' | translate }}</div>
              <div class="offer-name">{{ 'canal_plus' | translate }}</div>
              <button
                [disabled]="disabledButton"
                class="btn btn-outline-secondary btn-lg w-100 wkt-button border-left-radius border-right-radius"
                type="button"
                (click)="gotoCanalPlus(0)"
              >{{ getButtonLabel(0) | translate }}</button
              >
            </div>
          </div>
        </div>

        <div *ngIf="toutcanal" class="col-12 col-md-6 my-2">
          <div  class="row offer-height my-md-0 pt-md-2 mx-auto text-md-center h-100 justify-content-end">
            <div class="col-4 col-md-12 text-center d-flex align-items-center my-md-3">
              <wkt-logo class="mx-md-auto" [logoUrl]="'all_canalplus'"></wkt-logo
              ></div>
            <div class="col-8 border-xs-left mx-md-auto">
              <div class="offer-title">{{ 'canal_full_access' | translate }}</div>
              <div class="offer-subtitle">{{ 'canal_include_in_subscription' | translate }}</div>
              <div class="offer-name">{{ 'canal_plus_full' | translate }}</div>
              <button
                [disabled]="disabledButton"
                class="btn btn-outline-secondary btn-lg w-100 wkt-button border-left-radius border-right-radius"
                type="button"
                (click)="gotoCanalPlus(1)"
              >{{ getButtonLabel(1) | translate }}</button
              >
            </div>
          </div>
        </div>

        <div *ngFor="let offer of othersOffers"  class="col-12 col-md-6 my-2">
          <div class="row offer-height my-1 pb-md-2  pt-md-2 mx-auto text-md-center h-100 justify-content-end">
            <div class="col-4 col-md-12 text-center d-flex align-items-center my-md-3">
              <wkt-logo class="mx-md-auto offer-img-height" [externeUrl]="true"  [logoUrl]="offer.imageUrl"></wkt-logo
              ></div>
            <div class="col-8 border-xs-left mx-md-auto">
              <div class="offer-title">{{ offer.textHeaderLocalizationKey | translate }}</div>
              <div class="offer-subtitle">{{ offer.textDescriptionLocalizationKey | translate }}</div>
              <div class="offer-name">{{ offer.textProviderLocalizationKey | translate }}</div>
              <button
                [disabled]="disabledButton"
                class="btn btn-outline-secondary btn-lg w-100 wkt-button border-left-radius border-right-radius"
                type="button"
                (click)="gotoOthersOffers(offer)"
              >{{ offer.textButtonLocalizationKey | translate }}</button
              >
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>

<nav class="fixed-bottom p-0">
  <div class="container-fluid w-100 p-0 prevent-select">
    <div class="row  p-0">
      <div class="col-12 bg-light tierce-4-zone p-4">
        <div class="row tablet-screen-responsive mx-auto">
          <div class="col-4 text-center"><wkt-logo [logoUrl]="platform"></wkt-logo></div>
          <div class="col-8 border-left">
            <div class="offer-title">{{ 'iap_unlimited_access' | translate }}</div>
            <div class="offer-subtitle">{{ price }} / {{'iap_month' | translate}}</div>
            <button
              [disabled]="disabledSubscriptionButton"
              class="btn btn-secondary wkt-button-secondary-color-white btn-lg w-100 wkt-button border-left-radius border-right-radius"
              type="button"
              (click)="activateNatif()"
              >{{ subscriptionButtonLabel | translate }} </button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<style>
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  </style>
