import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AuthCodePageComponent } from './components/auth-code-page/auth-code-page.component';
import { AuthNotsendCodePageComponent } from './components/auth-notsend-code-page/auth-notsend-code-page.component';
import { IntroPageComponent } from './components/intro-page/intro-page.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { UserRoutingModule } from './user-routing.module';
import { UserProviderComponent } from './components/user-provider/user-provider.component';

@NgModule({
  declarations: [
    LoginPageComponent,
    IntroPageComponent,
    AuthCodePageComponent,
    AuthNotsendCodePageComponent,
    UserProviderComponent,
  ],
  imports: [SharedModule, UserRoutingModule],
})
export class UserModule {}
