export interface Country {
  code: string;
  name: string;
  phone?: string;
  legacy?: boolean;
  offers? : string[];
}
export let countriesList: Array<Country> = [
  { name: 'Afghanistan', phone: '93', code: 'AF' },
  { name: 'Albania', phone: '355', code: 'AL' },
  { name: 'Algeria', phone: '213', code: 'DZ' },
  { name: 'American Samoa', phone: '1-684', code: 'AS' },
  { name: 'Andorra', phone: '376', code: 'AD' },
  { name: 'Angola', phone: '244', code: 'AO' },
  { name: 'Anguilla', phone: '1-264', code: 'AI' },
  { name: 'Antarctica', phone: '672', code: 'AQ' },
  { name: 'Antigua and Barbuda', phone: '1-268', code: 'AG' },
  { name: 'Argentina', phone: '54', code: 'AR' },
  { name: 'Armenia', phone: '374', code: 'AM' },
  { name: 'Aruba', phone: '297', code: 'AW' },
  { name: 'Australia', phone: '61', code: 'AU' },
  { name: 'Austria', phone: '43', code: 'AT' },
  { name: 'Azerbaijan', phone: '994', code: 'AZ' },
  { name: 'Bahamas', phone: '1-242', code: 'BS' },
  { name: 'Bahrain', phone: '973', code: 'BH' },
  { name: 'Bangladesh', phone: '880', code: 'BD' },
  { name: 'Barbados', phone: '1-246', code: 'BB' },
  { name: 'Belarus', phone: '375', code: 'BY' },
  { name: 'Belgium', phone: '32', code: 'BE' },
  { name: 'Belize', phone: '501', code: 'BZ' },
  { name: 'Benin', phone: '229', code: 'BJ',  legacy: false },
  { name: 'Bermuda', phone: '1-441', code: 'BM' },
  { name: 'Bhutan', phone: '975', code: 'BT' },
  { name: 'Bolivia', phone: '591', code: 'BO' },
  { name: 'Bosnia and Herzegovina', phone: '387', code: 'BA' },
  { name: 'Botswana', phone: '267', code: 'BW' },
  { name: 'Brazil', phone: '55', code: 'BR' },
  { name: 'British Indian Ocean Territory', phone: '246', code: 'IO' },
  { name: 'British Virgin Islands', phone: '1-284', code: 'VG' },
  { name: 'Brunei', phone: '673', code: 'BN' },
  { name: 'Bulgaria', phone: '359', code: 'BG' },
  { name: 'Burkina Faso', phone: '226', code: 'BF',  legacy: false },
  { name: 'Burundi', phone: '257', code: 'BI' },
  { name: 'Cambodia', phone: '855', code: 'KH' },
  { name: 'Cameroon', phone: '237', code: 'CM', legacy: false },
  { name: 'Canada', phone: '1', code: 'CA' },
  { name: 'Cape Verde', phone: '238', code: 'CV' },
  { name: 'Cayman Islands', phone: '1-345', code: 'KY' },
  { name: 'Central African Republic', phone: '236', code: 'CF' },
  { name: 'Chad', phone: '235', code: 'TD' },
  { name: 'Chile', phone: '56', code: 'CL' },
  { name: 'China', phone: '86', code: 'CN' },
  { name: 'Christmas Island', phone: '61', code: 'CX' },
  { name: 'Cocos Islands', phone: '61', code: 'CC' },
  { name: 'Colombia', phone: '57', code: 'CO' },
  { name: 'Comoros', phone: '269', code: 'KM' },
  { name: 'Cook Islands', phone: '682', code: 'CK' },
  { name: 'Costa Rica', phone: '506', code: 'CR' },
  { name: "Côte d'ivoire", phone: '225', code: 'CI', legacy: false },
  { name: 'Croatia', phone: '385', code: 'HR' },
  { name: 'Cuba', phone: '53', code: 'CU' },
  { name: 'Curacao', phone: '599', code: 'CW' },
  { name: 'Cyprus', phone: '357', code: 'CY' },
  { name: 'Czech Republic', phone: '420', code: 'CZ' },
  { name: 'Democratic Republic of the Congo', phone: '243', code: 'CD',  legacy: false },
  { name: 'Denmark', phone: '45', code: 'DK' },
  { name: 'Djibouti', phone: '253', code: 'DJ' },
  { name: 'Dominica', phone: '1-767', code: 'DM' },
  { name: 'Dominican Republic', phone: '1-809, 1-829, 1-849', code: 'DO' },
  { name: 'East Timor', phone: '670', code: 'TL' },
  { name: 'Ecuador', phone: '593', code: 'EC' },
  { name: 'Egypt', phone: '20', code: 'EG' },
  { name: 'El Salvador', phone: '503', code: 'SV' },
  { name: 'Equatorial Guinea', phone: '240', code: 'GQ' },
  { name: 'Eritrea', phone: '291', code: 'ER' },
  { name: 'Estonia', phone: '372', code: 'EE' },
  { name: 'Ethiopia', phone: '251', code: 'ET' },
  { name: 'Falkland Islands', phone: '500', code: 'FK' },
  { name: 'Faroe Islands', phone: '298', code: 'FO' },
  { name: 'Fiji', phone: '679', code: 'FJ' },
  { name: 'Finland', phone: '358', code: 'FI' },
  { name: 'France', phone: '33', code: 'FR', },
  { name: 'French Polynesia', phone: '689', code: 'PF' },
  { name: 'Gabon', phone: '241', code: 'GA',  legacy: false },
  { name: 'Gambia', phone: '220', code: 'GM' },
  { name: 'Georgia', phone: '995', code: 'GE' },
  { name: 'Germany', phone: '49', code: 'DE' },
  { name: 'Ghana', phone: '233', code: 'GH' },
  { name: 'Gibraltar', phone: '350', code: 'GI' },
  { name: 'Greece', phone: '30', code: 'GR' },
  { name: 'Greenland', phone: '299', code: 'GL' },
  { name: 'Grenada', phone: '1-473', code: 'GD' },
  { name: 'Guam', phone: '1-671', code: 'GU' },
  { name: 'Guatemala', phone: '502', code: 'GT' },
  { name: 'Guernsey', phone: '44-1481', code: 'GG' },
  { name: 'Guinée', phone: '224', code: 'GN',  legacy: false },
  { name: 'Guinea-Bissau', phone: '245', code: 'GW' },
  { name: 'Guyana', phone: '592', code: 'GY' },
  { name: 'Haiti', phone: '509', code: 'HT' },
  { name: 'Honduras', phone: '504', code: 'HN' },
  { name: 'Hong Kong', phone: '852', code: 'HK' },
  { name: 'Hungary', phone: '36', code: 'HU' },
  { name: 'Iceland', phone: '354', code: 'IS' },
  { name: 'India', phone: '91', code: 'IN' },
  { name: 'Indonesia', phone: '62', code: 'ID' },
  { name: 'Iran', phone: '98', code: 'IR' },
  { name: 'Iraq', phone: '964', code: 'IQ' },
  { name: 'Ireland', phone: '353', code: 'IE' },
  { name: 'Isle of Man', phone: '44-1624', code: 'IM' },
  { name: 'Israel', phone: '972', code: 'IL' },
  { name: 'Italy', phone: '39', code: 'IT' },
  { name: 'Jamaica', phone: '1-876', code: 'JM' },
  { name: 'Japan', phone: '81', code: 'JP' },
  { name: 'Jersey', phone: '44-1534', code: 'JE' },
  { name: 'Jordan', phone: '962', code: 'JO' },
  { name: 'Kazakhstan', phone: '7', code: 'KZ' },
  { name: 'Kenya', phone: '254', code: 'KE' },
  { name: 'Kiribati', phone: '686', code: 'KI' },
  { name: 'Kosovo', phone: '383', code: 'XK' },
  { name: 'Kuwait', phone: '965', code: 'KW' },
  { name: 'Kyrgyzstan', phone: '996', code: 'KG' },
  { name: 'Laos', phone: '856', code: 'LA' },
  { name: 'Latvia', phone: '371', code: 'LV' },
  { name: 'Lebanon', phone: '961', code: 'LB' },
  { name: 'Lesotho', phone: '266', code: 'LS' },
  { name: 'Liberia', phone: '231', code: 'LR' },
  { name: 'Libya', phone: '218', code: 'LY' },
  { name: 'Liechtenstein', phone: '423', code: 'LI' },
  { name: 'Lithuania', phone: '370', code: 'LT' },
  { name: 'Luxembourg', phone: '352', code: 'LU' },
  { name: 'Macao', phone: '853', code: 'MO' },
  { name: 'Macedonia', phone: '389', code: 'MK' },
  { name: 'Madagascar', phone: '261', code: 'MG',  legacy: false},
  { name: 'Malawi', phone: '265', code: 'MW' },
  { name: 'Malaysia', phone: '60', code: 'MY' },
  { name: 'Maldives', phone: '960', code: 'MV' },
  { name: 'Mali', phone: '223', code: 'ML',  legacy: false },
  { name: 'Malta', phone: '356', code: 'MT' },
  { name: 'Marshall Islands', phone: '692', code: 'MH' },
  { name: 'Mauritania', phone: '222', code: 'MR' },
  { name: 'Mauritius', phone: '230', code: 'MU' },
  { name: 'Mayotte', phone: '262', code: 'YT' },
  { name: 'Mexico', phone: '52', code: 'MX' },
  { name: 'Micronesia', phone: '691', code: 'FM' },
  { name: 'Moldova', phone: '373', code: 'MD' },
  { name: 'Monaco', phone: '377', code: 'MC' },
  { name: 'Mongolia', phone: '976', code: 'MN' },
  { name: 'Montenegro', phone: '382', code: 'ME' },
  { name: 'Montserrat', phone: '1-664', code: 'MS' },
  { name: 'Morocco', phone: '212', code: 'MA' },
  { name: 'Mozambique', phone: '258', code: 'MZ' },
  { name: 'Myanmar', phone: '95', code: 'MM' },
  { name: 'Namibia', phone: '264', code: 'NA' },
  { name: 'Nauru', phone: '674', code: 'NR' },
  { name: 'Nepal', phone: '977', code: 'NP' },
  { name: 'Netherlands', phone: '31', code: 'NL' },
  { name: 'Netherlands Antilles', phone: '599', code: 'AN' },
  { name: 'New Caledonia', phone: '687', code: 'NC' },
  { name: 'New Zealand', phone: '64', code: 'NZ' },
  { name: 'Nicaragua', phone: '505', code: 'NI' },
  { name: 'Niger', phone: '227', code: 'NE',  legacy: false },
  { name: 'Nigeria', phone: '234', code: 'NG' },
  { name: 'Niue', phone: '683', code: 'NU' },
  { name: 'North Korea', phone: '850', code: 'KP' },
  { name: 'Northern Mariana Islands', phone: '1-670', code: 'MP' },
  { name: 'Norway', phone: '47', code: 'NO' },
  { name: 'Oman', phone: '968', code: 'OM' },
  { name: 'Pakistan', phone: '92', code: 'PK' },
  { name: 'Palau', phone: '680', code: 'PW' },
  { name: 'Palestine', phone: '970', code: 'PS' },
  { name: 'Panama', phone: '507', code: 'PA' },
  { name: 'Papua New Guinea', phone: '675', code: 'PG' },
  { name: 'Paraguay', phone: '595', code: 'PY' },
  { name: 'Peru', phone: '51', code: 'PE' },
  { name: 'Philippines', phone: '63', code: 'PH' },
  { name: 'Pitcairn', phone: '64', code: 'PN' },
  { name: 'Poland', phone: '48', code: 'PL' },
  { name: 'Portugal', phone: '351', code: 'PT' },
  { name: 'Puerto Rico', phone: '1-787, 1-939', code: 'PR' },
  { name: 'Qatar', phone: '974', code: 'QA' },
  { name: 'Republic of the Congo', phone: '242', code: 'CG',  legacy: false },
  { name: 'Reunion', phone: '262', code: 'RE' },
  { name: 'Romania', phone: '40', code: 'RO' },
  { name: 'Russia', phone: '7', code: 'RU' },
  { name: 'Rwanda', phone: '250', code: 'RW',  legacy: false },
  { name: 'Saint Barthelemy', phone: '590', code: 'BL' },
  { name: 'Saint Helena', phone: '290', code: 'SH' },
  { name: 'Saint Kitts and Nevis', phone: '1-869', code: 'KN' },
  { name: 'Saint Lucia', phone: '1-758', code: 'LC' },
  { name: 'Saint Martin', phone: '590', code: 'MF' },
  { name: 'Saint Pierre and Miquelon', phone: '508', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', phone: '1-784', code: 'VC' },
  { name: 'Samoa', phone: '685', code: 'WS' },
  { name: 'San Marino', phone: '378', code: 'SM' },
  { name: 'Sao Tome and Principe', phone: '239', code: 'ST' },
  { name: 'Saudi Arabia', phone: '966', code: 'SA' },
  { name: 'Senegal', phone: '221', code: 'SN',  legacy: false },
  { name: 'Serbia', phone: '381', code: 'RS' },
  { name: 'Seychelles', phone: '248', code: 'SC' },
  { name: 'Sierra Leone', phone: '232', code: 'SL' },
  { name: 'Singapore', phone: '65', code: 'SG' },
  { name: 'Sint Maarten', phone: '1-721', code: 'SX' },
  { name: 'Slovakia', phone: '421', code: 'SK' },
  { name: 'Slovenia', phone: '386', code: 'SI' },
  { name: 'Solomon Islands', phone: '677', code: 'SB' },
  { name: 'Somalia', phone: '252', code: 'SO' },
  { name: 'South Africa', phone: '27', code: 'ZA' },
  { name: 'South Korea', phone: '82', code: 'KR' },
  { name: 'South Sudan', phone: '211', code: 'SS' },
  { name: 'Spain', phone: '34', code: 'ES' },
  { name: 'Sri Lanka', phone: '94', code: 'LK' },
  { name: 'Sudan', phone: '249', code: 'SD' },
  { name: 'Suriname', phone: '597', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', phone: '47', code: 'SJ' },
  { name: 'Swaziland', phone: '268', code: 'SZ' },
  { name: 'Sweden', phone: '46', code: 'SE' },
  { name: 'Switzerland', phone: '41', code: 'CH', legacy: false },
  { name: 'Syria', phone: '963', code: 'SY' },
  { name: 'Taiwan', phone: '886', code: 'TW' },
  { name: 'Tajikistan', phone: '992', code: 'TJ' },
  { name: 'Tanzania', phone: '255', code: 'TZ' },
  { name: 'Thailand', phone: '66', code: 'TH' },
  { name: 'Togo', phone: '228', code: 'TG',  legacy: false},
  { name: 'Tokelau', phone: '690', code: 'TK' },
  { name: 'Tonga', phone: '676', code: 'TO' },
  { name: 'Trinidad and Tobago', phone: '1-868', code: 'TT' },
  { name: 'Tunisia', phone: '216', code: 'TN' },
  { name: 'Turkey', phone: '90', code: 'TR' },
  { name: 'Turkmenistan', phone: '993', code: 'TM' },
  { name: 'Turks and Caicos Islands', phone: '1-649', code: 'TC' },
  { name: 'Tuvalu', phone: '688', code: 'TV' },
  { name: 'U.S. Virgin Islands', phone: '1-340', code: 'VI' },
  { name: 'Uganda', phone: '256', code: 'UG' },
  { name: 'Ukraine', phone: '380', code: 'UA' },
  { name: 'United Arab Emirates', phone: '971', code: 'AE' },
  { name: 'United Kingdom', phone: '44', code: 'GB' },
  { name: 'United States', phone: '1', code: 'US' },
  { name: 'Uruguay', phone: '598', code: 'UY' },
  { name: 'Uzbekistan', phone: '998', code: 'UZ' },
  { name: 'Vanuatu', phone: '678', code: 'VU' },
  { name: 'Vatican', phone: '379', code: 'VA' },
  { name: 'Venezuela', phone: '58', code: 'VE' },
  { name: 'Vietnam', phone: '84', code: 'VN' },
  { name: 'Wallis and Futuna', phone: '681', code: 'WF' },
  { name: 'Western Sahara', phone: '212', code: 'EH' },
  { name: 'Yemen', phone: '967', code: 'YE' },
  { name: 'Zambia', phone: '260', code: 'ZM' },
  { name: 'Zimbabwe', phone: '263', code: 'ZW' },
];
