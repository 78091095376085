<div class="container-fuild h-100 p-3 prevent-select">
  <div class="row mt-3 main-content tablet-screen-responsive mx-auto">
    <div class="col-12 text-center align-self-center">
      <div class="mb-3 mt-5"></div>
      <h4>{{ 'send_code_error_title' | translate }}</h4>
      <div class="mb-3" style="word-break: break-all;">
        <h6>{{ email }}</h6>
      </div>
    </div>
    <div class="mt-5 text-center">{{ 'send_code_error_info' | translate }}
      <a class="text-secondary" (click)="faq()"> {{ 'send_code_error_info_action' | translate }} </a>

    </div>
    <div class="mt-5">
      <button class="btn btn-secondary wkt-button-secondary-color-white btn-lg h-20 w-100 mt-5" (click)="retry()"
        type="button">{{ 'send_code_error_retry' | translate }}</button>
    </div>
  </div>
</div>