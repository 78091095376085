import {
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpHandler,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injector, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  router!: Router;

  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let req = null;
    req = request.clone({
      headers: new HttpHeaders({
        'x-api-key': environment.apiKey
      }),
    });

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.router = this.injector.get(Router);

        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.message}`;
        } else {
          // server-side error
          console.log(error);
        }

        if (error && error.status && (error.status === 401 || error.status === 403)) {
          this.router.navigateByUrl('');
        }

        if (!!errorMessage) {
          //this.toastService.show(errorMessage, { classname: 'bg-white text-danger', delay: 3000 });
        }

        return throwError(error);
      })
    );
  }
}
