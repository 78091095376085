import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidatorProvider {
  public validateEmail(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const re = new RegExp('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$');

      const input = control.value;
      const isValid = re.test(input);

      if (!isValid) {
        return { validateEmail: { isValid } };
      } else {
        return {};
      }
    };
  }

  public validateNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const re = new RegExp('^-?\\d*(\\.\\d+)?$');
      if (control.value) {
        const input = control.value;
        const isValid = re.test(input);

        if (!isValid) {
          return { validateNumber: { isValid } };
        } else {
          return {};
        }
      } else {
        return {};
      }
    };
  }
}
