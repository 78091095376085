import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCodePageComponent } from './components/auth-code-page/auth-code-page.component';
import { AuthNotsendCodePageComponent } from './components/auth-notsend-code-page/auth-notsend-code-page.component';
import { IntroPageComponent } from './components/intro-page/intro-page.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { UserProviderComponent } from './components/user-provider/user-provider.component';
import { UserComponent } from './user.component';

const routes: Routes = [
  { path: '', redirectTo: 'intro', pathMatch: 'full' },
  {
    path: '',
    component: UserComponent,
    children: [
      {
        path: 'intro',
        component: IntroPageComponent,
      },
      {
        path: 'user/identifier',
        component: LoginPageComponent,
      },
      {
        path: 'user/code/verify',
        component: AuthCodePageComponent,
      },
      {
        path: 'user/code/notsent',
        component: AuthNotsendCodePageComponent,
      },
      {
        path: 'user/3rd_party_login',
        component: UserProviderComponent,
      },
      {
        path: 'user/3rd_party_login/:provider',
        component: UserProviderComponent,
      },
      {
        path: 'user/3rd_party_login/:provider/:section',
        component: UserProviderComponent,
      },
      {
        path: 'user/account',
        loadChildren: () => import('./../account/account.module').then((m) => m.AccountModule)
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
