import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectCountryService } from '../components/select-country/select-country.service';
import {STORE} from "../utils/constants";
import {init} from "@amplitude/analytics-browser";
import {environment} from "../../../environments/environment";
import {ServerZone} from "@amplitude/analytics-types";


export interface Language {
  code: string;
  name: string;
  native: string;
}

export interface Location {
  geoname_id: number;
  capital: string;
  languages: Language[];
  country_flag: string;
  country_flag_emoji: string;
  country_flag_emoji_unicode: string;
  calling_code: string;
  is_eu: boolean;
}

export interface RootObject {
  ip: string;
  type: string;
  continent_code: string;
  continent_name: string;
  country_code: string;
  country_name: string;
  region_code: string;
  region_name: string;
  city: string;
  zip: string;
  latitude: number;
  longitude: number;
  location: Location;
}

@Injectable({
  providedIn: 'root',
})


export class CommonService {

  get countryCode(): string | undefined {
    return this._countryCode;
  }

  private _countryCode : string = 'FR';
  private _alreadyInit = false;


  private ipStackUrl : string = 'https://api.ipstack.com/check?access_key=f68709367c60d55b206e7edd15ae4350&fields=country_code&language=en';

  constructor(private readonly http: HttpClient,
    private readonly selectCountryService: SelectCountryService) {
    this.checkMyCountry();
  }

  async initAmplitude() {
    //if (localStorage.getItem(STORE.DEVICE_ID) && !this._alreadyInit) {
    if (localStorage.getItem(STORE.DEVICE_ID)) {
      return await init(environment.amplKey, undefined, {
        serverZone: ServerZone.EU,
        deviceId: localStorage.getItem(STORE.DEVICE_ID)!,
        trackingOptions: {
          ipAddress: false
        },
        attribution: {
          trackPageViews: false,
        }
      }).promise
    } else {
      return await init(environment.amplKey, undefined, {
        serverZone: ServerZone.EU,
        trackingOptions: {
          ipAddress: false
        },
        attribution: {
          trackPageViews: false,
        }
      }).promise
    }
  }



  checkMyCountry() {

  }


}
