import { NgModule } from '@angular/core';
import { OfferComponent } from './offer.component';
import { OfferPageComponent } from './components/offer-page/offer-page.component';
import { SharedModule } from '../shared/shared.module';
import { OfferRoutingModule } from './offer-routing.module';

@NgModule({
  declarations: [OfferComponent, OfferPageComponent],
  imports: [SharedModule, OfferRoutingModule],
})
export class OfferModule {}
