<img
  id="wkt-logo"
  *ngIf="displayLogo('wtk')"
  src="../../../../assets/images/wkt_logo.jpg"
  class="img-fluid"
  alt="WAKATOON logo" />
<img
  *ngIf="displayLogo('all_canalplus')"
  src="../../../../assets/images/all_canalplus_logo.jpg"
  class="img-fluid wkt-logo"
  alt="WAKATOON logo" />
<img
  *ngIf="displayLogo('canalplus')"
  src="../../../../assets/images/canalplus_logo.jpg"
  class="img-fluid wkt-logo"
  alt="WAKATOON logo" />
<img
  *ngIf="displayLogo('app_store')"
  src="../../../../assets/images/app_store_logo.png"
  class="img-fluid wkt-logo-store"
  alt="WAKATOON logo" />
<img
  *ngIf="displayLogo('play_store')"
  src="../../../../assets/images/play_store_logo.png"
  class="img-fluid wkt-logo-store"
  alt="WAKATOON logo" />

  <img
  *ngIf="externeUrl"
  [src]="logoUrl"
  class="img-fluid wkt-logo offer-logo"
  alt="WAKATOON logo" />
