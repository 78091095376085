import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UnityService} from 'src/app/shared/callback/unity.service';
import {Country} from 'src/app/shared/components/select-country/select-country.data';
import {STORE} from 'src/app/shared/utils/constants';
import { v4 as uuidv4 } from 'uuid';
import {CommonService, RootObject} from "../../../shared/services/common.service";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {SelectCountryService} from "../../../shared/components/select-country/select-country.service";
import {HttpClient} from "@angular/common/http";
import {takeUntil} from "rxjs";
import {UserGatewayService} from "../../../shared/gateways/user-gateway.service";
import {track} from "@amplitude/analytics-browser";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'wkt-intro-page',
  templateUrl: './intro-page.component.html',
  styleUrls: ['./intro-page.component.scss'],
})
export class IntroPageComponent implements OnInit {
  private returnUrl!: string | '';
  private ipStackUrl: string = 'https://api.ipstack.com/check?access_key=f68709367c60d55b206e7edd15ae4350&fields=country_code&language=en';
  hideSearchBar = false;
  selectedCountry: Country = { code: 'FR', name: 'France', phone: '33', legacy: false };
  introSearchKeyword!: string | '';
  introForm!: FormGroup;
  // @ts-ignore
  tag: string = getVersion();
  version: string = "";
  private firstLoad = true;

  introPageUrl = '/assets/images/intro_image_transparent.png';
  promocode = '';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly unityService: UnityService,
    private readonly commonService: CommonService,
    private readonly formBuilder: FormBuilder,
    private readonly userGatewayService : UserGatewayService,
    private readonly selectCountryService: SelectCountryService,
    private readonly translate: TranslateService,
  ) { }

  ngOnInit(): void {

    localStorage.clear()

    this.selectCountryService.setInitialDefaultCountry();

    this.http.get(this.ipStackUrl)
      .subscribe(value => {
        console.log(" received from IPStack :", value)
        // @ts-ignore
        this.userGatewayService.getCountryByCode((value.country_code as string).toUpperCase())
          .subscribe(country => {
            this.selectedCountry = country
            this.selectCountryService.setDefaultCountry(country)
          })
      })

      // Get the current URL and store it in local storage
      const url = window.location.href;
      localStorage.setItem(STORE.INITIAL_URL, url);

      
    this.route.queryParams.subscribe((params) => {
      if (params['hide_search_bar']) {
        this.hideSearchBar = params['hide_search_bar'] !== 'false';
      } else {
        this.initIntroForm();
      }
      if (params['localized_price_string']) {
        localStorage.setItem(STORE.APPLICATON_PRICE, params['localized_price_string']);
      }
      localStorage.setItem(STORE.PLATFORM, 'android')
      if (params['platform']) {
        localStorage.setItem(STORE.PLATFORM, params['platform']);
      } else {

      }
      if (params[STORE.DEVICE_ID]) {
        localStorage.setItem(STORE.DEVICE_ID, params[STORE.DEVICE_ID]);
      }
      if (params['app_version']) {
        localStorage.setItem(STORE.APP_VERSION, params['app_version']);
        this.version = params['app_version'] + ' | ';
      }else{
        this.version = localStorage.getItem( STORE.APP_VERSION ) ?? '';
      }
      if (params['promocode']) {
        this.promocode = params['promocode'];
        this.userGatewayService.getOfferByPromoCode(params['promocode'])
        .subscribe((offer) => {
            if (!!offer.url) this.introPageUrl = offer.url
        })
      } else {
        this.introPageUrl = '/assets/images/intro_image.png'
        this.promocode = '';
      }
      this.commonService.initAmplitude().then(value => {
        const event = "intro";
        const prop = {
          "category": "navigation",
          "type": "page_view",
          "promocode": this.promocode
        }
        track(event, prop);
      })
    });


    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }


  goToHelp() {
    this.translate.get('help_url').subscribe(value => {
      this.unityService.goToUrl(value);
    })
  }

  start() {
    if (this.selectCountryService.defaultCountry?.legacy === true) {

    } else {
      const url = '/user/identifier?type=email' + (this.promocode == '' ? '' : '&promocode=' + this.promocode);
      this.router.navigateByUrl(url);
    }
    
    if (this.promocode === '') {
      this.unityService.setCountryToUnity(this.selectCountryService.defaultCountry);
    }
  }

  countrySelected(event: Country) {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    console.log('country ', event);
    this.selectedCountry = event;
    this.selectCountryService.setDefaultCountry(event);
    localStorage.setItem(STORE.USER_COUNTRY, JSON.stringify(event));
  }

  searchInfo() {
    const introSearchKeyword = (this.introForm.value.introSearchKeyword) as string;

    if (!!this.introForm.value.introSearchKeyword && introSearchKeyword.length >= 3)
    this.unityService.gotoUnitySearchResult(this.introForm.value.introSearchKeyword);
  }

  goTOCGV() {
    this.unityService.cgv()
  }

  goTOPrivacyPolicy() {
    this.unityService.privacyPolicy()
  }

  public throwTestError(): void {
    throw new Error("Sentry Test Error");
  }

  private initIntroForm() {
    this.introForm = this.formBuilder.group({
      introSearchKeyword: this.formBuilder.control(null, [Validators.required, Validators.minLength(3)]),
    });
  }
}
