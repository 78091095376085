import { Component, Input } from '@angular/core';

@Component({
  selector: 'wkt-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input() logoUrl: string | undefined;
  @Input() externeUrl: boolean = false;

  displayLogo(logoName: string) {
    if (logoName == 'wtk' && this.logoUrl === undefined) {
      return true;
    }
    if (logoName === this.logoUrl) return true;

    return false;
  }
}
