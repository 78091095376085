import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuildingComponent } from './shared/components/building/building.component';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'account/external-signin', redirectTo: 'user/3rd_party_login', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./user/user.module').then((m) => m.UserModule) },
  { path: 'offers', loadChildren: () => import('./offer/offer.module').then((m) => m.OfferModule) },
  { path: 'promo', loadChildren: () => import('./promo/promo.module').then((m) => m.PromoModule) },
  { path: '**', component: BuildingComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
