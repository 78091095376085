import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {CommonService} from "./shared/services/common.service";
import {init} from "@amplitude/analytics-browser";
import {environment} from "../environments/environment";
import BaseComponent from "bootstrap/js/dist/base-component";

@Component({
  selector: 'wkt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'wakatoon-web';
  private countryCode: string | undefined = 'FR';

  constructor(private translateService: TranslateService, private commenService: CommonService) {
    this.countryCode = commenService.countryCode

  }
}
