import { Component, EventEmitter,Input, OnInit, Output } from '@angular/core';
import { countriesList, Country } from './select-country.data';
import { UserGatewayService } from 'src/app/shared/gateways/user-gateway.service';
import { UnityService } from '../../callback/unity.service';
import { SelectCountryService } from './select-country.service';

@Component({
  selector: 'wkt-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
})
export class SelectCountryComponent implements OnInit {
  @Input() countriesInput: Array<string> = [
    'FR',
    'CH',
    'CI',
    'CM',
    'SN',
    'ML',
    'CD',
    'BF',
    'GA',
    'MG',
    'BJ',
    'CG',
    'TG',
    'GN',
    'NE',
    'RW',
  ];

  @Input() displayChangeCountry: boolean = false;

  @Output() selectedCountryEvent = new EventEmitter<Country>();
  @Output() selectedCountryCountryEvent = new EventEmitter<Country>();

  countriesFilter: Array<Country> = [];
  selectedCountry: Country;
  @Input() showChangeCountry: boolean = false;


  constructor(private readonly userGatewayService: UserGatewayService,
              private readonly unityService: UnityService,
              public selectCountryService: SelectCountryService) {
      this.selectedCountry = this.selectCountryService.defaultCountry
    }

  ngOnInit(): void {
    this.userGatewayService.getAllCountries().subscribe(countries => {
      if (countries != null && countries.length > 0) {
        this.countriesFilter = countries.sort(this.sortedfn)
        this.selectedCountryEvent.emit(this.selectedCountry);
      } else {
        this.countriesFilter = countriesList.sort(this.sortedfn)
        this.selectedCountryEvent.emit(this.selectedCountry);
      }
    });
  }

  sortedfn(a: Country, b: Country) : number {
    return a.name.localeCompare(b.name)
  }

  selectionCountry(country: Country) {
    if (country.legacy === undefined) country.legacy = false;
    this.selectedCountry = country;
    this.selectedCountryEvent.emit(this.selectedCountry);
    this.selectCountryService.setDefaultCountry(this.selectedCountry);
  }

  selectionCountryChange(country: Country) {
    if (country.legacy === undefined) country.legacy = false;
    this.selectedCountry = country;
    this.selectCountryService.setDefaultCountry(this.selectedCountry);
    this.selectedCountryCountryEvent.emit(this.selectedCountry);
  }

  flagCss(country: Country) {
    return 'fi fi-' + country.code.toLowerCase() + ' ';
  }

  changeCountry() {
    this.unityService.changeCountry();
  }
}
