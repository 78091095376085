<div class="container-fuild h-100 p-3 prevent-select">
  <div class="row mt-3 main-content tablet-screen-responsive mx-auto">
    <div class="col-12 text-center align-self-center">
      <div class="my-4">{{ 'account_deleted_message' | translate }}</div>

      <button
        class="btn btn-secondary wkt-button-secondary-color-white btn-lg w-100 my-3"
        (click)="continue()"
        type="button"
        >{{ 'account_deleted_continue' | translate }}</button
      >
    </div>
  </div>
</div>
