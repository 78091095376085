<div *ngIf="displayChangeCountry && selectCountryService.serviceReady"
  class="row justify-content-center tablet-screen-responsive country-zone">
  <div class="col-12 px-0"> <span>{{ 'my_account_country' | translate }} : </span></div>

  <div class="dropdown">
    <div class="row" id="dropdownMenuButton">
      <div class="col-5 wkt-display-change-country country-text-change px-1 py-4 py-md-auto">
        <span class="flag-border" [ngClass]="flagCss(selectCountryService.defaultCountry)"></span>
        <span class="mx-3">{{ selectCountryService.defaultCountry.name }}</span>
      </div>

      <div *ngIf="showChangeCountry" class="col-7 wkt-dropdown-country-change_button btn-lg pt-2 px-1">
        <button
          class="btn change-country w-100 px-0 btn-outline-dark btn-lg wkt-button border-left-radius border-right-radius"
          (click)="changeCountry()">
          {{ 'my_account_modify_country' | translate }}
        </button>
      </div>
    </div>

    <div class="dropdown-menu wk-select-country-change" aria-labelledby="dropdownMenuButton">
      <ul>
        <li *ngFor="let country of countriesFilter" class="dropdown-item" (click)="selectionCountryChange(country)">
          <span [ngClass]="flagCss(country)"></span> <span class="mx-3 country-name">{{ country.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="!displayChangeCountry" class="row justify-content-center tablet-screen-responsive country-zone">
  <!-- Button trigger modal -->
  <div class="row px-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
    <div class="col-9 wkt-display-country btn-lg border-left-radius country-text pt-3 dropdownMenuButton-zone">
      <span class="flag-border" [ngClass]="flagCss(selectCountryService.defaultCountry)"></span>
      <span class="mx-3">{{ selectCountryService.defaultCountry.name}}</span>
    </div>

    <div class="col-3 wkt-dropdown-country_button btn-lg border-right-radius pt-3 dropdownMenuButton-zone">
      <button class="btn dropdown-toggle w-100">
      </button>
    </div>
  </div>

</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title small" id="staticBackdropLabel">{{ 'intro_country' | translate }} : </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="contry-list">
          <ul class="m-0 p-0">
            <li *ngFor="let country of countriesFilter" class="country-item my-2 px-3 py-4"
              (click)="selectionCountry(country)" data-bs-dismiss="modal" aria-label="Close">
              <span [ngClass]="flagCss(country)"></span> <span class="mx-3">{{ country.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>