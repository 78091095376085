import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Country } from '../components/select-country/select-country.data';
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs";
import { STORE } from "../utils/constants";

export interface Lang {
  invalid_promo_code_instructions: string;
  valid_promo_code_instructions: string;
  identity_cgv_url: string
  identify_privacy_url: string
}

@Injectable({
  providedIn: 'root',
})
export class UnityService {
  private setCountryCallBackUrl = environment.appKey + '://action/user/country/set?value=';
  private userLoginCallBackUrl = environment.appKey + '://action/user/logIn?value=';
  private confirmUserLoggedCallbackUrl = environment.appKey + '://action/user/subsribed?uuid=';
  private unityHomeCallBackUrl = environment.appKey + '://home?version=3&country=';
  private setIdCallBackUrl = environment.appKey + '://action/user/set?uuid=';
  private searchCallBackUrl = environment.appKey + '://search?query=';
  private accountCallBackUrl = environment.appKey + '://action/user/account/back';
  private logoutCallBackUrl = environment.appKey + '://event/user/signed-out';
  private confirmDeletedUserCallBackUrl = environment.appKey + '://event/user/deleted';
  private userSubscriberCallBackUrl = environment.appKey + '://action/user/subsribed';
  private changeCountryCallBackUrl = environment.appKey + '://action/user/country/change';
  private clearCallbackUrl = environment.appKey + "://clearPartnerCookie?domains=https://pass.canalplus.com,https://data.canalplus.com";
  private iapCAllBack = environment.appKey + "://action/iap/initiate";
  private cgvCAllBack = environment.appKey + '://action/url/open/externally?url=';
  private iapRestore = environment.appKey + '://action/iap/restore';
  private bookLink = environment.appKey + '://home?version=3&country={{country}}&referrer=IDENTIFY';
  private actionDeletedUserCallBackUrl = environment.appKey + '://action/user/deleted';
  private actionValidePromoCodeCallBackUrl = environment.appKey + '://action/user/valid/offer'; // TODO mettre à jour ;

  constructor(private http: HttpClient, private translateService: TranslateService) { }

  setCountryToUnity(country: Country) {
    const url = this.setCountryCallBackUrl + country.code.toUpperCase() + '&legacy=' + country.legacy;
    console.log('setCountryToUnity', url);
    window.location.href = url;
  }

  setUserIdToUnity(userUuid: string) {
    const url = this.setIdCallBackUrl + userUuid;
    console.log('setUserIdToUnity', url);
    window.location.href = url;
  }

  clearCanalCookies() {
    const url = this.clearCallbackUrl;
    console.log('clearCallbackUrl', url);
    window.location.href = url;
  }

  userLoginToUnity(userUuid: string) {
    const url = this.userLoginCallBackUrl + userUuid;
    console.log('userLoginToUnity', url);
    window.location.href = url;
  }

  confirmUserLoggedToUnity(userUuid: string) {
    const url = `${this.confirmUserLoggedCallbackUrl}${userUuid}&message='Bravo,...'`;
    console.log('confirmUserLoggedToUnity', url);
    window.location.href = url;
  }

  gotoUnityHome(countryCode: string) {
    const url = `${this.unityHomeCallBackUrl}${countryCode.toUpperCase()}&referrer=app.wakatoon.com/intro`;
    console.log('legacy country', url);
    window.location.href = url;
  }

  gotoUnitySearchResult(keyword: string) {
    const url = `${this.searchCallBackUrl}${keyword.toUpperCase()}&referrer=SCREEN_COUNTRY_SELECT`;
    console.log('gotoUnitySearchResult', url);
    window.location.href = url;
  }

  gotoUnityAccountBack() {
    const url = `${this.accountCallBackUrl}`;
    console.log('gotoUnityAccountBack', url);
    window.location.href = url;
  }

  gotoUnityLogout() {
    const url = `${this.logoutCallBackUrl}`;
    console.log('gotoUnityLogout', url);
    window.location.href = url;
  }

  gotoConfirmDeletedUser() {
    const url = `${this.confirmDeletedUserCallBackUrl}`;
    console.log('gotoConfirmDeletedUser', url);
    window.location.href = url;
  }

  actionDeletedUser() {
    const url = `${this.actionDeletedUserCallBackUrl}`;
    console.log('actionDeletedUserCallBackUrl', url);
    window.location.href = url;
  }

  gotoUserSubscriber() {
    const url = `${this.userSubscriberCallBackUrl}`;
    console.log('gotoUserSubscriber', url);
    window.location.href = url;
  }

  changeCountry() {
    const url = `${this.changeCountryCallBackUrl}`;
    console.log('changeCountry', url);
    window.location.href = url;
  }

  activateNatif() {
    const url = `${this.iapCAllBack}`;
    console.log('changeCountry', url);
    window.location.href = url;

  }

  cgv() {
    this.http.get<Lang>('/assets/i18n/' + this.translateService.currentLang + '.json')
      .subscribe(value => {
        const json = `${this.cgvCAllBack}` + value.identity_cgv_url;
        console.log('changeCountry', json);
        window.location.href = json;
      })
  }

  privacyPolicy() {
    this.http.get<Lang>('/assets/i18n/' + this.translateService.currentLang + '.json')
      .subscribe(value => {
        const json = `${this.cgvCAllBack}` + value.identify_privacy_url;
        console.log('changeCountry', json);
        window.location.href = json;
      })
  }

  booklink(referrerLink: string) {
    const countryCode = (JSON.parse(localStorage.getItem(STORE.USER_COUNTRY)!) as Country).code;

    this.bookLink = this.bookLink.replace("{{country}}", countryCode!)
    this.bookLink = this.bookLink.replace("IDENTIFY", referrerLink)
    const url = `${this.bookLink}`;
    console.log('openBook link', url);
    window.location.href = url;
  }


  callNatifIap() {
    const url = `${this.iapRestore}`;
    console.log('changeCountry', url);
    window.location.href = url;
  }

  goToUrl(value: string) {
    const url = `${this.cgvCAllBack}` + value;
    console.log('Go to Url', url);
    window.location.href = url;
  }

  gotoUnityAfterValidPromo(oneTimeToken: string) {
    const url = `${this.actionValidePromoCodeCallBackUrl}` + oneTimeToken;
    console.log('gotoUnityAfterValidPromo', url);
    window.location.href = url;
  }


}
