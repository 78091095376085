import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account.component';
import { AccountDeletedPageComponent } from './components/account-deleted-page/account-deleted-page.component';
import { AccountDeletingPageComponent } from './components/account-deleting-page/account-deleting-page.component';
import { AccountPageComponent } from './components/account-page/account-page.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'account', pathMatch: 'full' },
  {
    path: '',
    component: AccountComponent,
    children: [
      {
        path: '',
        component: AccountPageComponent,
      },
      // {
      //  path: 'external-signin',
      //  component: AccountPageComponent,
      // },
      {
        path: 'delete',
        component: AccountDeletingPageComponent,
      },
      {
        path: 'deleted',
        component: AccountDeletedPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
