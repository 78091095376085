export enum STORE {
  USER_COUNTRY = 'country',
  USER_UUID = 'uuid',
  USER_PHONE = 'phone',
  USER_ACCES_TOKEN = 'access_token',
  USER_CANAL_AUTH_CODE = 'auth_code_canal',
  USER_IDENTIY_TYPE = "identify_type",
  APPLICATON_PRICE = "price",
  PLATFORM = 'platform',
  APP_VERSION = 'app_version',
  DEVICE_ID = 'amp_device_id',
  AMPLITUDE_INIT = 'is_ampl_init',
  PROMO_CODE = 'promocode',
  TRANSACTION_ATTEMPT = 'transaction_attempt',
  INITIAL_URL = 'initial_url'
}

export enum AUTH_ERROR {
  UNKNOWN  = 'Unknown',
  NO_RIGHT = 'No Right',
  DOUBLE_ACCOUNT = 'Double Account'
}
