export const environment = {
  production: true,
  apiBaseURL: 'https://api-staging.wakatoon.com',
  apiKey : '2VS2FFmTa45F9ld8o8HyP49rmr3jGZcX6yyc2DyR',
  appKey : 'wktst',
  amplKey : '96b295f31bb522daa99033a3a6b5437d',
  smartUrl : 'https://link-test.wakatoon.com/from-promocode?$ott=',
  auth2: {
    authority: 'https://pass.canal-plus.com/provider-IdPOAuth2/auth/Wakatoon',
    tokenUrl: 'https://pass.canal-plus.com/provider-IdPOAuth2/token/Wakatoon',
    redirectUrl: 'https://app-staging.wakatoon.com/user/3rd_party_login/canalplus/redirect',
    clientId: 'wakatoon',
    clientSecret: '9FC53FCC750EAE1BC52258CE647F330B2789AB4CBA308D423A74D29320B54ACE',
    scope: 'scope1 scope2 offline_access',
    responseType: 'code',
    grantType: 'authorization_code',
  },
};
