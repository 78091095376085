<div class="row mx-auto mt-3 main-content tablet-screen-responsive">
  <div class="col-12 text-center align-self-center">
    <div *ngIf="mainInfo !== ''" class="mb-3 mt-5"
      ><h4>{{ mainInfo | translate }}</h4></div
    >
    <div *ngIf="subInfo !== ''" class="mt-5">{{ subInfo | translate }}</div>

    <button
      *ngIf="buttonLabel !== '' && buttonUrl !== ''"
      class="btn btn-secondary wkt-button-secondary-color-white btn-lg w-100 mt-5"
      (click)="action()"
      type="button"
      >{{ buttonLabel | translate }}</button
    >
  </div>
</div>
