<div class="container-fuild h-100 p-0 prevent-select">

  <div class="row mt-3 h-100">
    <div class="col-12 tierce-zone p-3 mt-3">
      <wkt-logo></wkt-logo>
      <div class="intro-title text-center mt-3 prevent-select">
        {{ 'intro_title' | translate }}
      </div>
      <div *ngIf="hideSearchBar" name="intro-image">
        <img [src]="introPageUrl" class="img-fluid intro-image" alt="wakatoon_offer">
      </div>
      <div class="col-12 px-4 align-self-end align-self-md-start" [class.tierce-1-zone]="!hideSearchBar"
        [class.tierce-1-zone-img]="hideSearchBar">
        <div class="intro-country my-2">
          <!-- 
            <div class="mb-1 tablet-screen-responsive">{{ 'intro_country' | translate }}</div>
          -->
          <wkt-select-country (selectedCountryEvent)="countrySelected($event)"></wkt-select-country>
        </div>

        <div class="d-grid gap-2 my-2 tablet-screen-responsive mt-3">
          <!--<button (click)="throwTestError()">Test Sentry Error</button>-->
          <button
            class="btn btn-secondary btn-lg wkt-button-secondary-color-white border-left-radius border-right-radius wkt-button"
            (click)="start()" type="button">{{ 'intro_start' | translate }}</button>
        </div>
        <div class="text-center">

          <a (click)="goToHelp()" class="text-secondary">
            {{ 'help_url_prompt' | translate }}
          </a>
        </div>
        <div *ngIf="hideSearchBar" class="container intro-footer small">
          {{version}} {{ tag }}
        </div>
        <div class="tierce-pad-zone"></div>
      </div>
    </div>


    <div *ngIf="!hideSearchBar" class="col-12 bg-primary tierce-2-zone px-4 align-self-end">
      <div class="or-element"> {{ 'or' | translate}} </div>
      <div class="tierce-or-pad-zone"></div>
      <div class="tablet-screen-responsive small align-self-md-start text-center">{{ 'intro_keyword' | translate }}
      </div>
      <form [formGroup]="introForm" (ngSubmit)="searchInfo()">
        <div class="input-group wkt-button tablet-screen-responsive">
          <input name="introSearchKeyword" formControlName="introSearchKeyword" type="search"
            class="form-control border-left-radius" id="searchButton" aria-describedby="inputGroupFileAddon04"
            aria-label="search" placeholder="{{ 'intro_keyword_placeholder' | translate }}" />
          <button [disabled]="hideSearchBar"
            class="btn btn-secondary wkt-button-secondary-color-white btn-lg border-right-radius" type="submit"
            id="searchButton2">
            <i class="bi bi-search"></i>
          </button>
        </div>

      </form>
      <div class="mb-0 text-center small" Style="margin-bottom: 0rem">{{ 'identify_cgv' | translate }}
        <a (click)="goTOCGV()" class="text-secondary">
          {{ 'identify_cgv_action' | translate }}
        </a>
        {{ 'and' | translate }}
        <a (click)="goTOPrivacyPolicy()" class="text-secondary">
          {{ 'identify_privacy_action' | translate }}
        </a>
      </div>

      <div class="container intro-footer align-self-end" Style="margin-bottom: 0rem">
        {{version}} {{ tag }}
      </div>

    </div>


  </div>

</div>