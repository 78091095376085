import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http-service';
import {Offer, User, UserCreateResponse, UserOfferResponse, UserPromo} from '../models/user.model';
import { Country } from '../components/select-country/select-country.data';
import {
  AccountValidationRequest,
  ValidationCodeRequest,
  ValidationCodeResponse,
} from '../models/validation-code.model';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root',
})
export class UserGatewayService {
  private notificationSMSurl = '/user/v1.0/send/sms';
  private notificationEmailSurl = '/user/v1.0/send/email';
  private accessValidationCodeUrl = '/user/v1.0/validate/code';
  private authenticatedeUrl = '/user/v1.0/authenticate';
  private userUuidUrl = '/user/v1.0/uuid/';
  private userMailUrl = '/user/v1.0/email/';  
  private userdeleteUuidUrl = '/user/v1.0/delete/uuid/';
  private countrieUrl = '/user/v1.0/offers/countries';
  private countryByCodeUrl = '/user/v1.0/offers/country/';
  private offerByCodeUrl = '/user/v1.0/offers/promo/{code}';
  private checkPromoCodeUrl = '/user/v1.0/validate/user/{uuid}/promo-code/{promoCode}/check';
  private activateOffer = '/user/v1.0/activate/offer';

  constructor(private readonly httpService: HttpService, private readonly translateService: TranslateService) {

  }

  getCodeBySMS(phone: string, countryCode: string, promoCode: string): Observable<UserCreateResponse> {
    const body = {
      phone: phone,
      countryCode: countryCode,
      language: this.translateService.getBrowserLang()!,
      promoCode: promoCode
    };
    return this.httpService.post(this.notificationSMSurl, body);
  }

  getCodeByEmail(address: string, countryCode: string, promoCode: string): Observable<UserCreateResponse> {
    const body = {
      address: address,
      countryCode: countryCode,
      language: this.translateService.getBrowserLang()!,
      promoCode: promoCode
    };
    return this.httpService.post(this.notificationEmailSurl, body);
  }

  valideCode(code: string, userId: string): Observable<User> {
    const body: AccountValidationRequest = {
      code: code,
      userUuid: userId
    };
    return this.httpService.post(this.accessValidationCodeUrl, body);
  }
  authenticate(code: string, userId: string): Observable<ValidationCodeResponse> {
    const body: AccountValidationRequest = {
      code: code,
      userUuid: userId
    };
    return this.httpService.post(this.authenticatedeUrl, body);
  }

  getUser(uuid: string): Observable<User> {
    return this.httpService.get(this.userUuidUrl + uuid);
  }

  getUserByMail(mail: string): Observable<User> {
    return this.httpService.get(this.userMailUrl + mail);
  }

  deleteUser(uuid: string) {
    return this.httpService.post(this.userdeleteUuidUrl + uuid,null);
  }

  getAllCountries(): Observable<Country[]> {
    return this.httpService.get(this.countrieUrl);
  }

  getCountryByCode(countryCode: string): Observable<Country> {
    return this.httpService.get(this.countryByCodeUrl + countryCode.toUpperCase());
  }

  getOfferByPromoCode(promoCode: string): Observable<Offer>{
    const url = this.httpService.buildUrlWithPathVaribale(this.offerByCodeUrl,'{code}', promoCode);
    return this.httpService.get(url);
  }

  checkPromoCode(uuid: string, promoCode: string): Observable<UserPromo> {
    let url = this.httpService.buildUrlWithPathVaribale(this.checkPromoCodeUrl,'{uuid}', uuid);
    url = this.httpService.buildUrlWithPathVaribale(url,'{promoCode}', promoCode);
    return this.httpService.get(url);
  }

  validOfferCode(uuid: string, offer: string): Observable<UserOfferResponse> {
    let url = this.httpService.buildUrlWithPathVaribale(this.activateOffer,'{uuid}', uuid);
    return this.httpService.post(url,{
      userUuid: uuid,
      offerUuid: offer
    });
  }
}
